import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Obsat from '../views/Obsat.vue'
import WebApp from '../views/WebApp.vue'

import Activities from '../views/Activities.vue'
import Configs from '../views/Configs.vue'
import Library from '../views/Library.vue'
import Profile from '../views/Profile.vue'
import Mission from '../views/Mission.vue'
import About from '../views/About.vue'
import Admin from '../views/Admin.vue'
import store from '../store'

Vue.use(VueRouter)

let authGuard = function(to, from, next){
  if(store.getters.user){
    next()
  }
  else {
    next('/')
  }
}

const routes = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/obsat',
    name: 'Obsat',
    component: Obsat,
  },
  {
    path: '/webapp',
    name: 'WebApp',
    component: WebApp,
    beforeEnter: authGuard,
    children: [
      {
        path: 'home',
        component: Home
      },
      {
        path: 'activities',
        component: Activities
      },
      {
        path: 'configs',
        component: Configs
      },
      {
        path: 'library',
        component: Library
      },
      {
        path: 'mission',
        component: Mission
      },
      {
        path: 'profile',
        component: Profile
      },
      {
        path: 'about',
        component: About
      },
      {
        path: 'admin',
        component: Admin
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
