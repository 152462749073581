import Vue from 'vue'
import Vuex from 'vuex'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore';

Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    user: null,
    sat: null,
    sats: [],
    rastreadores: null,
    loading: false,
    error: false,
  },

  mutations: {
    setUser(state, payload){
      state.user = payload
    },
    setSat(state, payload){
      state.sat = payload
    },
    setSats(state, payload){
      state.sats = payload
    },
    setRastreadores(state, payload){
      state.rastreadores = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
    setError (state, payload) {
      state.error = payload
    },
    clearError (state) {
      state.error = null
    },
  },

  actions: {

    async signIn({commit,dispatch},payload){
      try {
        await firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
        dispatch("loadUser")
      } catch (error) {
        commit('setError', error)
      }
    },

    async autoSignIn({dispatch},payload){
      if(payload){
        await dispatch('loadUser')
      }
    },

    logOut({commit}){
      firebase.auth().signOut()
      commit('setUser',null)
      
    },

    async signUp ({commit}, payload) {
      commit('setLoading', true)
      commit('clearError')
      const db = firebase.firestore()
      const collection = db.collection('users')
      
      try {
        await firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
        delete payload.password
        commit('setLoading', false)       
        commit('setUser', payload)
        
        payload.uid = firebase.auth().currentUser.uid
        payload.createdAt = firebase.firestore.FieldValue.serverTimestamp()
        payload.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
        await collection.doc(payload.uid).set(payload)

        let snapshot = await db.collection('satellite').where("serial", "==", payload.serial_used).get()
        if (snapshot.empty) {
          console.log('No matching documents.');
          return
        }
        snapshot.forEach(async (doc) => {
          db.collection('satellite').doc(doc.ref.id).update('registered_users', firebase.firestore.FieldValue.arrayUnion(payload.uid))
        })

        commit('setLoading', false)

      } catch (error) {
        commit('setLoading', false)
        commit('setError', error)
        console.error(error)
      }
    },

    //Action to load from the database the user profile data.
    async loadUser ({commit}) {
      try {
        let query = await firebase.firestore().collection('users').where("uid", "==", firebase.auth().currentUser.uid).get()
        let userData = null
        query.forEach(function(doc) {
          userData = doc.data();
          userData.uid = firebase.auth().currentUser.uid
        })
        commit('setUser', userData)
      } catch (error) {
        console.error(error)
      }
    },

    async updateProfile({commit}, payload) {
      commit('setLoading', true)
      commit('clearError')
      payload.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
      let query = await firebase.firestore().collection('users').where("uid", "==", firebase.auth().currentUser.uid).get()
      query.forEach((doc) => {
        firebase.firestore().collection('users').doc(doc.id).update(payload)
        commit('setLoading', false)
      })
      commit('setUser', payload)
    },

    async checkSerial({commit}, serial) {
      commit('setLoading', true)
      commit('clearError')
      let result = false
      
      let query = await firebase.firestore().collection('satellite').where("serial", "==", serial).get()
      
      if(!query.empty){
        result = true
        commit('setLoading', false)
      } else {
        result = false
        commit('setLoading', false)
      }
      return result
    },

    async checkcep({commit}, cep) {
      commit('setLoading', true)
      commit('clearError')
      let result = false
      
      let query = await firebase.firestore().collection('localizador').where("email", "==", cep).get()
      
      if(!query.empty){
        result = true
        commit('setLoading', false)
      } else {
        result = false
        commit('setLoading', false)
      }
      return result
    },

    async addSat({commit}, payload){
      commit('setLoading', true)
      commit('clearError')
      const db = firebase.firestore()
      await db.collection('satellite').add(payload)
      commit('setLoading', false)
    },

    async loadSat({commit}) {
      commit('setLoading', true)
      commit('clearError')

      const db = firebase.firestore()

      let snapshot = await db.collection('satellite').where("registered_users", "array-contains", this.state.user.uid).get()

      if (snapshot.empty) {
        console.log('No matching documents.');
        return
      }

      snapshot.forEach(async (doc) => {
        commit('setSat', doc.data())
      })

      commit('setLoading', false)
    },

    async loadRastreadores({commit}) {
      commit('setLoading', true)
      commit('clearError')

      const db = firebase.firestore()

      let snapshot = await db.collection('localizador').get()

      if (snapshot.empty) {
        console.log('No matching documents.');
        return
      }

      let rastreadores = []
      snapshot.forEach(async (doc) => {
        rastreadores.push(doc.data())
      })

      commit('setLoading', false)
      commit('setRastreadores', rastreadores)
    },

    async loadSats({commit}) {
      commit('setLoading', true)
      commit('clearError')

      const db = firebase.firestore()

      let snapshot = await db.collection('satellite').get()

      if (snapshot.empty) {
        console.log('No matching documents.');
        return
      }

      let sats = []
      snapshot.forEach(async (doc) => {
        sats.push(doc.data())
      })

      commit('setLoading', false)
      commit('setSats', sats)
    },

    async sendMessage({commit}, payload){
      commit('setLoading', true)
      commit('clearError')

      const db = firebase.firestore()
      payload.user = this.state.user.uid
      payload.sendTime = firebase.firestore.FieldValue.serverTimestamp()
      payload.read = false
      payload.solved = false
      await db.collection('messages').add(payload)

      commit('setLoading', false)
    },

    //Action for cleaning the error state.
    clearError ({commit}) {
      commit('clearError')
    },

  },

  getters: {
    user(state){
      return state.user
    },
    sat(state){
      return state.sat
    },
    sats(state){
      return state.sats
    },
    rastreadores(state){
      return state.rastreadores
    },
    loading (state) {
      return state.loading
    },
    error (state) {
      return state.error
    },
  }

})
