<template>
  <v-container class="px-10 pt-10">
    <v-row>
      <v-col sm="12" md="6">
        <v-card
          class="rounded-lg pa-2"
          flat
        >
          <v-card-title>Cadastro de Serial Number</v-card-title>
          <v-card-text>
            <v-form
              ref="verSat"
              lazy-validation
            >
              <v-row>
                <v-col cols=4>
                  <v-text-field
                    v-model="serial"
                    label="Número de Série"
                    :rules="[rules.required]"
                    color="#0D2556"
                  ></v-text-field>
                </v-col>
                <v-col cols=4>
                  <v-select
                    v-model="sat_type"
                    :items="sats"
                    :rules="[rules.required]"
                    label="Tipo de Satélite"
                    color="#0D2556"                  
                  ></v-select>
                </v-col>
                <v-col cols=4>
                <v-select
                  v-model="lote"
                  :items="lotes"
                  label="Lote"
                  :rules="[rules.required]"
                  color="#0D2556"                  
                ></v-select>
                </v-col>
                <v-col cols=4>
                  <v-select
                    label="Versão"
                    :items="versions"
                    :rules="[rules.required]"
                    v-model="version"
                    color="#0D2556"   
                    class="pt-2"                 
                  ></v-select>
                </v-col>
                <v-col cols=4>
                  <v-text-field
                    label="Cor"
                    v-model="color"
                    :rules="[rules.required]"
                    color="#0D2556"
                    class="pt-2"
                  ></v-text-field>
                </v-col>
                <v-col cols=4>
                  <v-select
                    label="Data de Fabricação"
                    :items="manufactures"
                    v-model="manufacture"
                    :rules="[rules.required]"
                    color="#0D2556"   
                    class="pt-2"                 
                  ></v-select>
                </v-col>
                <v-col cols=4>
                  <v-text-field
                    label="Máximo de Usuários"
                    v-model="max_users"
                    :rules="[rules.required]"
                    value="10"
                    color="#0D2556"
                    class="pt-2"
                  ></v-text-field>
                </v-col>
                <v-col cols=4>
                  <v-text-field
                    label="Prazo de Garantia"
                    v-model="warranty_exp"
                    :rules="[rules.required]"
                    color="#0D2556"
                    class="pt-2"
                  ></v-text-field>
                </v-col>
                <v-col cols=4>
                  <v-text-field
                    label="Validade PION Classroom"
                    v-model="classroom_exp"
                    :rules="[rules.required]"
                    color="#0D2556"
                    class="pt-2"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div class="text-center d-flex justify-center pb-3">
                <v-btn
                  dark
                  outlined
                  color="#0D2556"
                  class="profile-btn"
                  @click="addSat"
                >
                  Cadastrar Satélite
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" md="6">
        <v-card
          class="rounded-lg"
          flat
        >
        <v-card-title>Kits PION Satélites Cadastrados</v-card-title>
          <v-data-table
            :headers="headers"
            :items="satellites"
            :items-per-page="4"
            class="elevation-1"
          ></v-data-table>
        </v-card>
      </v-col>
      <v-col sm="12" md="6">
        <v-card
          class="rounded-lg"
          flat
        >
          <v-tabs v-model="tab" grow>
            <v-tab>
              Novas
            </v-tab>
            <v-tab>Respondidas</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-list two-line>
                <v-list-item-group
                  v-model="selected"
                  multiple
                >
                  <template v-for="(item, index) in messages">
                    <v-list-item :key="item.subject">
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.subject"></v-list-item-title>

                          <v-list-item-subtitle
                            class="text--primary"
                            v-text="item.name"
                          ></v-list-item-subtitle>

                          <v-list-item-subtitle v-text="item.message"></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-list-item-action-text v-text="item.time"></v-list-item-action-text>

                          <v-icon
                            v-if="!active"
                            color="grey lighten-1"
                          >
                            mdi-check
                          </v-icon>

                          <v-icon
                            v-else
                            color="yellow darken-3"
                          >
                            mdi-check
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="index < satellites.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-tab-item>
            <v-tab-item>
              Test2
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import 'firebase/storage';
  export default {
    data: () => ({
      tab: null,
      rules: {
        required: value => !!value || 'Required.',
      },
      serial: "",
      color: "",
      max_users: 10,
      warranty_exp: "31/12/2021",
      classroom_exp: "30/06/2022",
      sat_type: null,
      sats: [
        'CanSat', 'CubeSat',
      ],
      lote: null,
      lotes: [
        'Lote 2020-01', 'Lote 2020-02','Lote 2021-03',
      ],
      version: null,
      versions: [
        'Versão Out/20', 'Versão Nov/20','Versão Jan/21',
      ],
      manufacture: null,
      manufactures: [
        '03/11/2020', '18/12/2020','30/01/2021',
      ],
      selected: [1],
      messages: [
        {
          subject: 'Sat Prob',
          name: 'John',
          message: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
          time: '15/46/5465',
        },
      ],
      headers: [
          {
            text: 'Tipo',
            align: 'start',
            value: 'sat_type',
          },
          { text: 'Lote', value: 'lote' },
          { text: 'Versão', value: 'version' },
          { text: 'Cor', value: 'color' },
          { text: 'Garantia', value: 'warranty_exp' },
        ],
    }),
    methods: {
      addSat(){
        if(this.$refs.verSat.validate()){
          this.$store.dispatch('addSat',
          {
            serial: this.serial,
            color: this.color,
            max_users: this.max_users,
            warranty_exp: this.warranty_exp,
            classroom_exp: this.classroom_exp,
            sat_type: this.sat_type,
            lote: this.lote,
            version: this.version,
            manufacture: this.manufacture
          })
          alert("Satélite criado na base de dados!");
          this.$store.dispatch('loadSats')
        }
      }
    },
    computed: {
      satellites(){
        return this.$store.getters.sats
      }
    },
    beforeMount: function(){
      this.$store.dispatch('loadSats')
    }
  }
</script>

<style>
  .footer-about {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: #0D2556;
  }
</style>