<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" class="px-10 pt-5">
        <span class="title-std pb-2">Missão Espacial</span>
        <v-row>

          <v-col cols="5">
            <v-card
              class="rounded-lg pa-2"
              flat
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card-title justify="start">
                      <span class="card-title">Equipe da Missão</span>
                    </v-card-title>
                    <v-container fluid>
                      <v-row justify="space-around">
                        <v-flex xs12 sm12 md12 lg6
                          v-for="user in users"
                          :key="user.id"
                        >            
                          <v-card
                            class="mx-auto"
                            elevation="0"
                            flat
                          >
                            <v-list-item three-line>
                              <v-list-item-avatar size="58" class="d-none d-md-flex">
                                <img :src="user.avatar">
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>{{ user.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                                <v-list-item-subtitle>
                                  <v-chip v-if="user.leader == '1'"
                                    color="pion"
                                    text-color="white"
                                    x-small
                                  >
                                    Líder
                                  </v-chip>
                                  <v-chip v-if="user.leader == '0'"
                                    color="gray"
                                    text-color="graypion"
                                    x-small
                                  >
                                    Membro
                                  </v-chip>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </v-flex>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card-title justify="start"><span class="card-title">Adicionar Membros na Equipe</span></v-card-title>
                    <v-alert
                      border="left"
                      color="blue"
                      dense
                      text
                      type="warning"
                      class="ml-4"
                    >
                      <span class="alert-profile">Membros adicionados necessitam completar o cadastro para acessarem a plataforma.</span>
                    </v-alert>
                    <v-container fluid class="px-4">
                      <v-row justify="space-around">
                        <v-col cols="12" class="d-flex justify-start align-center">
                          <span class="texto-garantia">O líder da equipe no <strong>PION Classroom</strong> pode adicionar até <strong>dez membros</strong> ao projeto da missão. Os membros cadastrados não podem ser removidos posteriormente.</span>
                        </v-col>
                      </v-row>
                      <v-row justify="space-around" class="pt-2">
                        <v-col cols="12" class="d-flex justify-center align-center">        
                          <v-dialog
                            v-model="adddialog"
                            persistent
                            max-width="600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-if="status == '1'"
                                dark
                                rounded
                                color="#0D2556"
                                class="profile-btn"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon
                                  dark
                                  left
                                  small
                                >
                                  mdi-account-plus
                                </v-icon>
                                Adicionar Novo Membro
                              </v-btn>
                              <v-btn
                                v-if="status == '0'"
                                disabled
                                depressed
                                rounded
                                class="profile-btn"
                              >
                                <v-icon
                                  dark
                                  left
                                  small
                                >
                                  mdi-account-plus
                                </v-icon>
                                Adicionar Novo Membro
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title>
                                <span class="headline">Adicionar Novo Membro</span>
                              </v-card-title>
                              <v-card-text>
                                <div class="mt-2">
                                  Ao adicionar um novo membro, ele terá acesso à <strong>Plataforma PION Classroom</strong> após conclusão do cadastro.
                                  Lembre-se que não é possível cadastrar mais do que dez membros ao seu projeto!
                                </div>
                              </v-card-text>
                              <v-form
                                ref="cadastro"
                                v-model="valid"
                                lazy-validation
                              >
                                <v-text-field
                                  v-model="email"
                                  :rules="[rules.required, rules.email]"
                                  label="E-mail"
                                  required
                                  outlined
                                  placeholder="E-mail"
                                  color="#0D2556"
                                  class="mx-6 pb-2"
                                ></v-text-field>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="gray"
                                    text
                                    @click="adddialog = false"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    dark
                                    color="#0D2556"
                                    @click="register"
                                  >
                                    Cadastrar
                                  </v-btn>
                                </v-card-actions>
                              </v-form>
                            </v-card>
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="7">
            <v-card
              class="rounded-lg pa-2"
              flat
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card-title justify="start"><span class="card-title">Perfil da Missão</span></v-card-title>
                    <v-container fluid class="pt-4 px-6">
                      <v-row justify="space-around">
                        <v-avatar size="140" class="my-3 d-none d-md-flex">
                          <img
                            src="https://www.spacex.com/static/images/share.jpg"
                            alt="SpaceX"
                          >
                        </v-avatar>
                      </v-row>
                      <v-row justify="space-around">
                        <span class="profile-name mt-3">SpaceX</span>
                      </v-row>
                      <v-row justify="space-around">
                        <span class="profile-subname mt-2">Colégio da Penha III</span>
                      </v-row>
                      <v-row justify="space-around" class="mt-6">
                        <v-col cols="2"></v-col>
                        <v-col cols="2" align="center" justify="center">
                          <v-row justify="space-around">
                            <span class="profile-dados1 mt-3">5</span>
                          </v-row>
                          <v-row justify="space-around">
                            <span class="profile-dados2 mt-3">Atividades<br>Concluídas</span>
                          </v-row>
                        </v-col>
                        <v-col cols="1" align="center" justify="center"><v-divider vertical></v-divider></v-col>
                        <v-col cols="2" align="center" justify="center">
                          <v-row justify="space-around">
                            <span class="profile-dados1 mt-3">87%</span>
                          </v-row>
                          <v-row justify="space-around">
                            <span class="profile-dados2 mt-3">Média das<br>Notas</span>
                          </v-row>
                        </v-col>
                        <v-col cols="1" align="center" justify="center"><v-divider vertical></v-divider></v-col>
                        <v-col cols="2" align="center" justify="center">
                          <v-row justify="space-around">
                            <span class="profile-dados1 mt-3">12</span>
                          </v-row>
                          <v-row justify="space-around">
                            <span class="profile-dados2 mt-3">Insígnias<br>Recebidas</span>
                          </v-row>
                        </v-col>
                        <v-col cols="2"></v-col>
                      </v-row>
                      <v-row justify="start" class="mt-10 profile-title">
                        Informações da Missão
                      </v-row>
                      <v-row justify="space-around" class="mt-4">
                        <v-col cols="3" class="d-flex justify-start align-center">
                          <span class="profile-dis-field">Nome da Missão</span>
                        </v-col>
                        <v-col cols="9">
                          <v-text-field
                            label="Nome Completo"
                            value="Starship SN-9"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="space-around">
                        <v-col cols="3" class="d-flex justify-start align-center">
                          <span class="profile-dis-field">Líder da Missão</span>
                        </v-col>
                        <v-col cols="9">
                          <v-text-field
                            label="Nome do Líder"
                            value="Elon Musk"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="space-around">
                        <v-col cols="3" class="d-flex justify-start align-center">
                          <span class="profile-dis-field">Criação do Perfil</span>
                        </v-col>
                        <v-col cols="9">
                          <v-text-field
                            prepend-icon="mdi-calendar"
                            label="Data de Criação do Perfil"
                            value="28/12/2020"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="space-around">
                        <v-col cols="3" class="d-flex justify-start align-center">
                          <span class="profile-dis-field">Sensores da Missão</span>
                        </v-col>
                        <v-col cols="9">
                          <v-select
                            v-model="sensors1"
                            :items="sensors2"
                            label="Sensores Escolhidos"
                            attach
                            chips
                            multiple
                            disabled
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        adddialog: false,
        status: '1',
        rules: {
          required: value => !!value || 'Preencher campo.',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'E-mail inválido.'
          },
        },  
        sensors1: ['Giroscópio', 'Detector de CO2'],
        sensors2:  [
          'Magnetômetro',
          'Inercial',
          'Giroscópio',
          'Detector de CO2',
          'Umidade',
          'Temperatura',
          'Detector de Luz Ambiente',
          'Nível da Bateria',
        ],
        users: [
          {
            id: 1,
            leader: "1",
            name: "Elon Musk",
            email: "elom@spacex.com",
            avatar: "https://static.newsbreak.com/people/600/per_thumb_31bb51c2b3d04a2aa57efe38543dee2a.jpg",
          },
          {
            id: 3,
            leader: "0",
            name: "John Rockets",
            email: "elom@spacex.com",
            avatar: "https://static.newsbreak.com/people/600/per_thumb_31bb51c2b3d04a2aa57efe38543dee2a.jpg",
          },
          {
            id: 4,
            leader: "0",
            name: "Flango Yamato",
            email: "elom@spacex.com",
            avatar: "https://static.newsbreak.com/people/600/per_thumb_31bb51c2b3d04a2aa57efe38543dee2a.jpg",
          },
          {
            id: 5,
            name: "Calvera",
            leader: "0",
            email: "elom@spacex.com",
            avatar: "https://static.newsbreak.com/people/600/per_thumb_31bb51c2b3d04a2aa57efe38543dee2a.jpg",
          },
          {
            id: 6,
            name: "Brunão",
            leader: "0",
            email: "elom@spacex.com",
            avatar: "https://static.newsbreak.com/people/600/per_thumb_31bb51c2b3d04a2aa57efe38543dee2a.jpg",
          },
          {
            id: 2,
            name: "Elon Musk",
            leader: "0",
            email: "elom@spacex.com",
            avatar: "https://static.newsbreak.com/people/600/per_thumb_31bb51c2b3d04a2aa57efe38543dee2a.jpg",
          },
          {
            id: 7,
            name: "Elon Musk",
            leader: "0",
            email: "elom@spacex.com",
            avatar: "https://static.newsbreak.com/people/600/per_thumb_31bb51c2b3d04a2aa57efe38543dee2a.jpg",
          },
          {
            id: 8,
            name: "Elon Musk",
            leader: "0",
            email: "elom@spacex.com",
            avatar: "https://static.newsbreak.com/people/600/per_thumb_31bb51c2b3d04a2aa57efe38543dee2a.jpg",
          },
          {
            id: 9,
            name: "Elon Musk",
            leader: "0",
            email: "elom@spacex.com",
            avatar: "https://static.newsbreak.com/people/600/per_thumb_31bb51c2b3d04a2aa57efe38543dee2a.jpg",
          },
          {
            id: 10,
            name: "Elon Musk",
            leader: "0",
            email: "elom@spacex.com",
            avatar: "https://static.newsbreak.com/people/600/per_thumb_31bb51c2b3d04a2aa57efe38543dee2a.jpg",
          },
        ]
      }
    },
    methods: {
      register(){
        if(this.$refs.cadastro.validate()){
          this.$router.push('/webapp/home') // Aqui virá o login do Firebase
        }
      },
    }
  }
</script>

<style scoped>
  .texto-garantia {
    font-family: 'Nunito';
    font-size: 15px;
    font-weight: 400;
    color: #50535A;
    text-align: justify;
  }
  .profile-btn {
    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: normal;
  }
  .alert-profile {
    font-family: 'Nunito';
    font-size: 13px;
    font-weight: 300;
    letter-spacing: normal;
  }
  .profile-dis-field {
    font-family: 'Nunito';
    font-size: 12px;
    font-weight: 400;
    color: #50535A;
  }
  .profile-title {
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 600;
    color: #50535A;
  }
  .profile-name {
    font-family: 'Nunito';
    font-size: 20px;
    font-weight: 700;
    color: #0D2556;
  }
  .profile-subname {
    font-family: 'Nunito';
    font-size: 15px;
    font-weight: 300;
    color: #50535A;
  }
  .profile-dados1 {
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 800;
    color: #50535A;
  }
  .profile-dados2 {
    font-family: 'Nunito';
    font-size: 12px;
    font-weight: 300;
    color: #50535A;
  }
  .card-title{
    font-family: 'Nunito';
    font-size: 18px;
    font-weight: 600;
    color: #50535A;
  }
  .title-std {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
  }
  .input-width{
    min-width: 170px;
  }
  .small-input-width{
    min-width: 90px;
  }
  .big-input-width{
    min-width: 200px;
  }
</style>