<template>
  <v-container class="bod">
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" class="px-10 pt-5">
        <span class="title-std pb-2">Perfil do Usuário</span>
        <v-row>
          <v-col cols="7">
            <v-card
              class="rounded-lg pa-2"
              flat
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card-title justify="start"><span class="card-title">Meu Perfil</span></v-card-title>
                    <v-container fluid class="pt-2 px-6">
                      <v-row justify="space-around">
                        <v-avatar size="140" class="my-3" color=pion >
                          <span v-if="user.profileimageurl == null" class="white--text headline">
                            {{user.name == undefined ? null: user.name.split(" ")[0][0]+this.user.name.split(" ")[1][0]}}
                          </span>
                          <img v-else
                              :src="user.profileimageurl"
                              :alt="name"
                              style="object-fit: cover;"
                            >
                        </v-avatar>
                      </v-row>
                      <v-row justify="space-around">
                        <span class="profile-name mt-3">{{user.name}}</span>
                      </v-row>
                      <v-row justify="space-around">
                        <span class="profile-subname mt-2">{{user.institution}}</span>
                      </v-row>
                      <v-row justify="space-around" class="mt-6">
                        <v-col cols="2"></v-col>
                        <!-- <v-col cols="2" align="center" justify="center">
                          <v-row justify="space-around">
                            <span class="profile-dados1 mt-3">5</span>
                          </v-row>
                          <v-row justify="space-around">
                            <span class="profile-dados2 mt-3">Atividades<br>Concluídas</span>
                          </v-row>
                        </v-col>
                        <v-col cols="1" align="center" justify="center"><v-divider vertical></v-divider></v-col>
                        <v-col cols="2" align="center" justify="center">
                          <v-row justify="space-around">
                            <span class="profile-dados1 mt-3">87%</span>
                          </v-row>
                          <v-row justify="space-around">
                            <span class="profile-dados2 mt-3">Média das<br>Notas</span>
                          </v-row>
                        </v-col>
                        <v-col cols="1" align="center" justify="center"><v-divider vertical></v-divider></v-col>
                        <v-col cols="2" align="center" justify="center">
                          <v-row justify="space-around">
                            <span class="profile-dados1 mt-3">12</span>
                          </v-row>
                          <v-row justify="space-around">
                            <span class="profile-dados2 mt-3">Insígnias<br>Recebidas</span>
                          </v-row>
                        </v-col> -->
                        <v-col cols="2"></v-col>
                      </v-row>
                      <v-row justify="start" class="mt-10 profile-title">
                        Informações Básicas
                      </v-row>
                      <v-row justify="space-around" class="mt-4">
                        <v-col cols="3" class="d-flex justify-start align-center">
                          <span class="profile-dis-field">Nome Completo</span>
                        </v-col>
                        <v-col cols="9">
                          <v-text-field
                            label="Nome Completo"
                            :value="user.name"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="space-around">
                        <v-col cols="3" class="d-flex justify-start align-center">
                          <span class="profile-dis-field">E-mail</span>
                        </v-col>
                        <v-col cols="9">
                          <v-text-field
                            label="E-mail"
                            :value="user.email"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="space-around">
                        <v-col cols="3" class="d-flex justify-start align-center">
                          <span class="profile-dis-field">Data de Nascimento</span>
                        </v-col>
                        <v-col cols="9">
                          <v-text-field
                            prepend-icon="mdi-calendar"
                            label="Data de Nascimento"
                            :value="(user.dob).split('-')[2]+'/'+(user.dob).split('-')[1]+'/'+(user.dob).split('-')[0]"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="space-around">
                        <v-col cols="3" class="d-flex justify-start align-center">
                          <span class="profile-dis-field">Criação do Perfil</span>
                        </v-col>
                        <v-col cols="9">
                          <v-text-field
                            prepend-icon="mdi-calendar"
                            label="Data de Criação do Perfil"
                            :value="new Date(user.createdAt.seconds*1000+user.createdAt.nanoseconds/1000000).toLocaleString('pt-BR',{dateStyle:'short'})"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-card
              class="rounded-lg pa-2"
              flat
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card-title justify="start"><span class="card-title">Alterar Perfil</span></v-card-title>
                    <v-container fluid class="pt-2 px-6">
                      <v-row>
                        <v-alert
                          border="left"
                          color="blue"
                          dense
                          text
                          type="warning"
                          class="ml-4"
                        >
                            <span class="alert-profile">Após clicar em Alterar Foto ou Altetar Dados, as informações serão atualizadas.</span>
                        </v-alert>
                      </v-row>
                      <v-row justify="start" class="my-4 profile-title">
                        Atualização da sua Foto
                      </v-row>
                      <v-file-input
                        accept="image/png, image/jpeg, image/bmp"
                        placeholder="Selecione sua nova foto do perfil"
                        prepend-icon="mdi-camera"
                        :rules="[rules.size]" 
                        v-model="profileImage"
                        color="#0D2556"
                      ></v-file-input>
                      <div class="text-center d-flex justify-center py-1">
                        <v-btn
                          dark
                          outlined
                          color="#0D2556"
                          class="profile-btn"
                          @click="updateProfile"
                        >
                          Alterar Foto
                        </v-btn>
                      </div>
                      <v-row justify="start" class="mt-2 profile-title">
                        Atualização do seu Perfil
                      </v-row>
                      <v-form
                        ref="alterar_profile"
                        lazy-validation
                      >
                        <v-text-field
                          v-model="name"
                          label="Nome Completo"
                          :rules="[rules.required]"
                          color="#0D2556"
                          class="mt-4"
                        ></v-text-field>
                        <v-text-field
                          label="Celular"
                          v-model="celular"
                          :rules="[rules.required]"
                          color="#0D2556"
                          hint="Utilizar o formato (XX) XXXXXXXXX"
                          class="pt-2"
                        ></v-text-field>
                        <v-text-field
                          label="Cidade"
                          v-model="city"
                          :rules="[rules.required]"
                          color="#0D2556"
                          class="pt-2"
                        ></v-text-field>
                        <v-select
                          v-model="uf"
                          :items="ufs"
                          label="Estado"
                          :rules="[rules.required]"
                          color="#0D2556"   
                          class="pt-2"                 
                        ></v-select>
                        <v-text-field
                          label="Nome da Instituição de Ensino ou Organização"
                          v-model="institution"
                          :rules="[rules.required]"
                          color="#0D2556"
                          class="pt-2"
                        ></v-text-field>
                        <div class="text-center d-flex justify-center pb-3">
                          <v-btn
                            dark
                            outlined
                            color="#0D2556"
                            class="profile-btn"
                            @click="updateProfile"
                            :loading="loading"
                          >
                            Alterar Dados
                          </v-btn>
                        </div>
                      </v-form>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/storage';

  export default {
    data: () => ({
      profileImage:null,
      rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 6 || 'Min 6 characters',
          size: value => !value || value.size < 2000000 || 'Tamanho da imagem deve ser menos de 2 MB!'
      },
      name: "",
      celular: "",
      city:"",
      uf: null,
      ufs: [
        'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO',
      ],
      institution:"",
    }),
    computed: {
      user(){
        return this.$store.getters.user
      },
      error () {
        return this.$store.getters.error
      },
      loading () {
        return this.$store.getters.loading
      },
    },
    methods: {
      async updateProfile(){
        if(this.$refs.alterar_profile.validate()){
          if(this.profileImage != null){
            await firebase.storage().ref().child("Profiles/ProfileImages/"+firebase.auth().currentUser.uid+this.profileImage.name).put(this.profileImage)
            this.user.profileimageurl = await firebase.storage().ref().child("Profiles/ProfileImages/"+firebase.auth().currentUser.uid+this.profileImage.name).getDownloadURL()
            this.storeItems()  
          }else{
            this.storeItems()
          }
        }
      },

      storeItems(){
        this.user.name = this.name
        this.user.celular = this.celular
        this.user.city = this.city
        this.user.uf = this.uf
        this.user.institution = this.institution
        this.$store.dispatch('updateProfile',this.user)
      }
    },
    beforeMount: function(){
      this.$store.dispatch('loadUser')
      this.name = this.user.name
      this.celular = this.user.celular
      this.city = this.user.city
      this.uf = this.user.uf
      this.institution = this.user.institution
    }
  }
</script>

<style scoped>
  .bod{
    font-family: 'Nunito';
  }
  .profile-btn {
    font-weight: 500;
    text-transform: capitalize;
  }
  .alert-profile {
    font-family: 'Nunito';
    font-size: 13px;
    font-weight: 300;
    letter-spacing: normal;
  }
  .profile-dis-field {
    font-family: 'Nunito';
    font-size: 12px;
    font-weight: 400;
    color: #50535A;
  }
  .profile-title {
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 600;
    color: #50535A;
  }
  .profile-name {
    font-family: 'Nunito';
    font-size: 20px;
    font-weight: 700;
    color: #0D2556;
  }
  .profile-subname {
    font-family: 'Nunito';
    font-size: 15px;
    font-weight: 300;
    color: #50535A;
  }
  .profile-dados1 {
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 800;
    color: #50535A;
  }
  .profile-dados2 {
    font-family: 'Nunito';
    font-size: 12px;
    font-weight: 300;
    color: #50535A;
  }
  .card-title{
    font-family: 'Nunito';
    font-size: 18px;
    font-weight: 600;
    color: #50535A;
  }
  .title-std {
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
  }
</style>