<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" class="px-10 pt-5">
        <v-row>
          <v-col cols="12" sm="12" class="justify-center">
            <span class="title-std pb-2">Atividades</span>
            <v-card
              class="rounded-lg pa-3"
              flat
            >
              <v-row justify="center">
                <v-col cols="12" class="py-4">
                  <v-img
                    src="@/assets/PION_Classroom_Gray.svg"
                    max-height="100px"
                    contain
                  ></v-img>
                </v-col>
              </v-row>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Procurar Atividade"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="activities"
                :search="search"
                :footer-props="{
                  'items-per-page-text':'Atividades por página',
                  'items-per-page-all-text':'Todas'
                }"
              >
                <template v-slot:[`item.rating`]="{ item }">
                  <v-rating
                    length="5"
                    v-model="item.rating"
                    readonly="true"
                    small
                    dense
                    color="graypion"
                    background-color="graypion"
                  ></v-rating>
                </template>
                <template v-slot:[`item.status`]="{ item }" >
                  <span v-if="item.status == '0'" class="status-0" @click="GoToActivity(item)">
                    <v-icon small color="pion">mdi-arrow-right-drop-circle-outline</v-icon>
                    Começar!
                  </span>
                  <span v-if="item.status == '1'" class="status-1" @click="GoToActivity(item)">
                    <v-icon small color="#F5B100">mdi-history</v-icon>
                    Continuar
                  </span>
                  <span v-if="item.status == '2'" class="status-2" @click="GoToActivity(item)">
                    <v-icon small color="#07BD3A">mdi-check-circle-outline</v-icon>
                    Concluída
                  </span>
                </template>
                <template v-slot:[`item.link`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="GoToActivity(item)"
                  >
                    mdi-open-in-new
                  </v-icon>
                </template>
                <template v-slot:[`footer.page-text`]="items"> {{ items.pageStart }} a {{ items.pageStop }} de {{ items.itemsLength }} </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Atividade',
            align: 'start',
            filterable: true,
            value: 'name',
          },
          { text: 'Código', value: 'code' },
          { text: 'Nível', value: 'section' },
          { text: 'Dificuldade', value: 'rating' },
          //{ text: 'BNCC', value: 'bncc' },
          //{ text: 'ONU 2030', value: 'onu' },
          //{ text: 'Status', value: 'status'},
          { text: 'Link', value: 'link', sortable: false },
        ],
        activities: [
          {
            name: 'Cálculo da Altitude',
            code: 'A.001.20',
            section: 'Iniciante',
            rating: '2',
            bncc: 'AN2120',
            onu: 'OBJSUST',
            link: './activities/teste.pdf',
            status: '0',
          },
          {
            name: 'Abrindo o Dashboard de Dados do meu Satélite',
            code: 'A.002.20',
            section: 'Iniciante',
            rating: '2',
            bncc: 'AN5000',
            onu: 'OBJRH',
            link: '@/webapp/about',
            status: '0',
          },
          {
            name: 'Montando meu CanSat',
            code: 'L.001.20',
            section: 'Iniciante',
            rating: '5',
            bncc: 'AN5000',
            onu: 'OBJRH',
            link: '@/webapp/about',
            status: '0',
          },
          {
            name: 'Montando meu CubeSat',
            code: 'Q.002.20',
            section: 'Iniciante',
            rating: '4',
            bncc: 'AN5000',
            onu: 'OBJRH',
            link: '@/webapp/about',
            status: '0',
          },
          {
            name: 'Aquecimento Global e o Gás Carbônico 1',
            code: 'P.001.20',
            section: 'Iniciante',
            rating: '1',
            bncc: 'AN5000',
            onu: 'OBJRH',
            link: '@/webapp/about',
            status: '2',
          },
          {
            name: 'Aquecimento Global e o Gás Carbônico 2',
            code: 'P.002.20',
            section: 'Intermediário',
            rating: '2',
            bncc: 'AN5000',
            onu: 'OBJRH',
            link: '@/webapp/about',
            status: '1',
          },
          {
            name: 'Aquecimento Global e o Gás Carbônico 3',
            code: 'P.003.20',
            section: 'Avançado',
            rating: '4',
            bncc: 'AN5000',
            onu: 'OBJRH',
            link: '@/webapp/about',
            status: '0',
          },
          {
            name: 'O que é um CubeSat?',
            code: 'Q.001.20',
            section: 'Iniciante',
            rating: '1',
            bncc: 'AN5000',
            onu: 'OBJRH',
            link: '@/webapp/about',
            status: '0',
          },
          {
            name: 'Básico sobre Sensores: Magnetômetro',
            code: 'S.001.20',
            section: 'Avançado',
            rating: '4',
            bncc: 'AN5000',
            onu: 'OBJRH',
            link: '@/webapp/about',
            status: '2',
          },
          {
            name: 'Básico sobre Sensores: Giroscópio',
            code: 'S.002.20',
            section: 'Avançado',
            rating: '4',
            bncc: 'AN5000',
            onu: 'OBJRH',
            link: '@/webapp/about',
            status: '2',
          },
        ],
      }
    },
    methods: {
      GoToActivity (item) {
        this.$router.push(item.link)
      },
    },
  }
</script>

<style>
.status-0 {
  color: #0D2556;
  font-family: Nunito;
  font-weight: 700;
  font-size: 13px;
}
.status-1 {
  color: #F5B100;
  font-family: Nunito;
  font-weight: 700;
  font-size: 13px;
}
.status-2 {
  color: #07BD3A;
  font-family: Nunito;
  font-weight: 700;
  font-size: 13px;
}
.v-list-item__subtitle {
  color:#000000;
  font-size: 12px;
}
.title-std {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}
.texto-normal {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: justify;
}
.texto-azul {
  color: #0D2556;
}
.texto-strong {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  color: #4A5057;

}
.texto-azul-strong {
  font-weight: bold;
  color: #0D2556;
}
.footer1 {
  margin-top: 0;
  text-align: center;
}
.footer2 {
  margin-top: 1vh;
  font-size: 12px;
  text-align: center;
  color: #50535A;
}
</style>