import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'

import AlertCmp from './components/Shared/Alert.vue'
Vue.component('app-alert', AlertCmp)

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyBkhWVdu2qwdeW6Qo5GlwQ5ElWaeMTkkUY",
  authDomain: "classroom-e67ad.firebaseapp.com",
  databaseURL: "https://classroom-e67ad.firebaseio.com",
  projectId: "classroom-e67ad",
  storageBucket: "classroom-e67ad.appspot.com",
  messagingSenderId: "130756145308",
  appId: "1:130756145308:web:3bf06eb871e0bdeed8fd59",
  measurementId: "G-WEJQD940PD"
};

firebase.initializeApp(firebaseConfig)
let app
firebase.auth().onAuthStateChanged((user)=>{
  if (!app){
    app = new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  app.$store.dispatch('autoSignIn',user)
  }
})


