<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      min-width="25vh"
      color="#0D2556"
    >
      <div class="logo-nav">
        <v-img
          src="@/assets/PION_Classroom.svg"
          contain
          max-width="45%"
        ></v-img>
      </div>
      <v-list flat>
        <v-list-item-group
          link
          mandatory
          height="100"
          class="mt-3 py-4 mx-2 justify-center"
          dark
        >
          <v-list-item
            class="list-item1"
            v-for="item in items"
            :key="item.text"
            :to ="item.routepath"
          >
          <span>
              <v-list-item-icon class="pa-2 darkpion">
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
          </span>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            href="https://github.com/pion-labs/pion-educational-kits/wiki"
            target="_blank"
            class="list-item1"
            color="#ffffff"
            dark
          >
            <v-list-item-icon class="pa-2 darkpion">
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sat Wiki</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="onLogout"
            class="list-item1"
            color="#ffffff"
            dark
          >
            <v-list-item-icon class="pa-2 darkpion">
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <div class="footer1 d-flex justify-center">
        <v-img
          src="@/assets/LogoSimplesFFF.svg"
          max-width="5vh"
          contain
        ></v-img>
      </div>
      <div class="footer2 d-flex justify-center">
          Feito com ♥ no Brasil
      </div>
    </v-navigation-drawer>
    <v-app-bar
      color="white"
      app
      flat
      clipped-left
      height="55px"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <v-img
          src="@/assets/PION_Icon.svg"
          max-height="45px"
          contain
          position="left"
        ></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="text-capitalize welcome mx-4 d-none d-md-flex">Olá, {{firstName}}!</div>
      <v-menu
        min-width="200px"
        rounded
        offset-y
        left
      >
        <template v-slot:activator="{ on }">
          <v-btn
            class="mr-1"
            icon
            x-large
            v-on="on"
          >
            <v-avatar
              color="#560303"
              size="48"
            >
              <span v-if="user.profileimageurl == null" class="white--text headline">{{initials}}</span>
              <img v-else
                :src="user.profileimageurl"
                :alt="name"
                style="object-fit: cover;"
              >
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
                color="#560303"
              >
                <span v-if="user.profileimageurl == null" class="white--text headline">{{initials}}</span>
                <img v-else
                  :src="user.profileimageurl"
                  :alt="name"
                  style="object-fit: cover;"
                >
              </v-avatar>
              <h3 class="text-capitalize mx-2 name-menu">{{ name }}</h3>
              <p class="email-menu">
                {{ user.email }}
              </p>
              <v-divider></v-divider>
              <v-btn
                depressed
                block
                text
                color="white"
                class="buttom-menu text-capitalize"
                to="/webapp/profile"
              >
                Editar Perfil
              </v-btn>
              <v-divider></v-divider>
              <v-btn
                @click="onLogout"
                depressed
                block
                text
                color="white"
                class="buttom-menu mb-n3 text-capitalize"                
              >
                Sair
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main :style="$vuetify.theme.dark ? 'background-color: #8C8E92;' : 'background-color: #27a9e1;'">
      <slot></slot>
    </v-main>
  </v-app>
</template>

<script>
  document.title = 'PION Classroom'
  export default {
    props: {
      source: String,
    },
    data: () => ({
      drawer: null,
      name: "",
      initials: '',
      firstName: '',
      items: [
        { icon: 'mdi-home', text: 'Home', routepath: '/webapp/home' },
        //{ icon: 'mdi-library', text: 'Biblioteca', routepath: '/webapp/library' },
        //{ icon: 'mdi-pencil-box-multiple', text: 'Atividades', routepath: '/webapp/activities' },
        //{ icon: 'mdi-satellite-variant', text: 'Missão', routepath: '/webapp/mission'},
        { icon: 'mdi-satellite-variant', text: 'Meu Satélite', routepath: '/webapp/configs'},
        { icon: 'mdi-account-circle', text: 'Meu Perfil', routepath: '/webapp/profile'},
      ],
    }),
    methods: {
      onLogout() {
        this.$store.dispatch('logOut')
        this.$router.push('/')
      }
    },
    computed: {
      user(){
        return this.$store.getters.user
      },
    },
    beforeMount: function(){
        if(this.user){
          this.name = this.user.name.toLowerCase()
          this.firstName = (this.user.name.split(" ")[0]+" "+this.user.name.split(" ")[1]).toLowerCase()
          this.initials = this.user.name.split(" ")[0][0]+this.user.name.split(" ")[1][0]
          if(this.user.role == "admin")
            this.items.push({ icon: 'mdi-lock', text: 'Admin', routepath: '/webapp/admin'})
      }
    }
  }
</script>

<style scoped>
  .v-list .v-list-item--active .v-icon {
    color: #ffffff;
  }
  .v-list .v-list-item--active {
    color: #ffffff;
  }
  #app {
    font-family: 'Nunito','Roboto', 'Ubuntu', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
  .user-name{
    font-weight: 700;
  }
  .logo-nav {
    margin-top: 4vh;
    display: flex;
    justify-content: center;
  }
  .list-item1 {
    padding-top: 8px;
    padding-bottom: 8px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  .welcome {
    font-size: 15px;
    text-align: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    color: #50535A;
  }
  .name-menu {
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    color: #50535A;
  }
  .email-menu {
    margin-top: 5px;
    font-size: 12px;
    text-align: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    color: #50535A;
  }
  .buttom-menu {
    font-size: 12px;
    text-align: center;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    color: #50535A;
  }
  .footer1 {
    margin-top: 3vh;
    font-size: 13px;
    text-align: center;
  }
  .footer2 {
    margin-top: 1vh;
    font-size: 12px;
    text-align: center;
    color: #ffffff;
  }
</style>