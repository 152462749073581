import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme:{
        themes:{
            light:{
                lightblue: "#01579B",
                lightpion: "#27a9e1",
                darkblue: "#0D47A1",
                darkpion: "#081632",
                pion: "#0D2556",
                graypion: "#50535A"
            }
        }
    }
});
