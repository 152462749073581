<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" class="px-10 pt-5">
        <v-row>
          <v-col cols="12" sm="12" class="justify-center">
            <span class="title-std pb-2">Biblioteca de Vídeos</span>
            <v-row dense no-gutters>
              <v-flex xs12 sm6 md4 lg3 class="my-4"
                v-for="video in videos"
                :key="video.id"
              >            
                <v-layout row wrap>
                  <v-card
                    :loading="loading"
                    class="mx-auto my-3"
                    max-width="340"
                  >
                    <v-img
                      height="140"
                      :src="video.cover"
                    ></v-img>
                    <v-card-title>{{ video.title }}</v-card-title>
                    <v-card-text>
                      <v-row
                        align="center"
                        class="mx-0"
                      >
                        <template>
                          <v-chip v-if="video.chip == '0'"
                            color="orange"
                            text-color="white"
                            small
                          >
                            Avançado
                          </v-chip>
                          <v-chip v-if="video.chip == '1'"
                            color="indigo"
                            text-color="white"
                            small
                          >
                            CanSat
                          </v-chip>
                          <v-chip v-if="video.chip == '2'"
                            color="teal"
                            text-color="white"
                            small
                          >
                            CubeSat
                          </v-chip>
                          <v-chip v-if="video.chip == '3'"
                            color="pion"
                            text-color="white"
                            small
                          >
                            PION
                          </v-chip>
                          <v-chip v-if="video.chip == '4'"
                            color="green"
                            text-color="white"
                            small
                          >
                            Geral
                          </v-chip>
                        </template>
                      </v-row>
                      <v-row
                        align="center"
                        class="mx-0 mt-5"
                      >
                        {{ video.desc }}
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="video.release == '0'"
                        text
                        class="profile-btn"
                        @click="reserve"
                        color="#959ca5"
                      >
                        Em breve
                        <v-icon small left color="#959ca5">
                          mdi-close
                        </v-icon>
                      </v-btn>
                      <v-dialog
                        v-if="video.release == '1'"
                        v-model="dialog"
                        persistent
                        transition="dialog-top-transition"
                        max-width="1000px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            class="profile-btn"
                            color="#959ca5"
                            v-bind="attrs"
                            v-on="on"
                          >
                            Assistir
                            <v-icon v-icon small left color="#959ca5">
                              mdi-open-in-new
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:default="dialog">
                          <v-card>
                            <v-card-title>
                              <span class="headline">Conheça a PION!</span>
                            </v-card-title>
                            <v-subheader class="subheader">A PION é uma startup brasileira que está democratizando o acesso ao espaço por meio do desenvolvimento de foguetes e satélites!</v-subheader>
                            <v-row no-gutters justify="space-around">
                              <v-col
                                class="mx-4 mb-4"
                              >            
                                <v-card
                                  class="pa-3 d-flex align-center justify-center"
                                  flat
                                >
                                  <iframe id="video1" width="960" height="600" src="https://www.youtube.com/embed/BviU2_gSu6M" frameborder="0" controls="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </v-card>
                              </v-col>
                            </v-row>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="gray"
                                text
                                @click="dialog.value = false"
                                onclick="document.getElementById('video1').pause();"
                              >
                                Fechar
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-card-actions>
                  </v-card>
                </v-layout>
              </v-flex>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        videos: [
          {
            id: 8,
            title: "Conheça a PION!",
            desc: "A PION é uma startup brasileira que está democratizando o acesso ao espaço por meio do desenvolvimento de foguetes e satélites!",
            url: "https://www.youtube.com/embed/vWl8ppkPxoM",
            cover: "https://1.bp.blogspot.com/-Gmw6AE9p2IQ/XhOO1jOunmI/AAAAAAABBuk/mPdz2MaQ9p8yylWYbZgZ5sbvPQSQ2Lw4ACLcBGAsYHQ/s1600/Update5.jpg",
            chip: "3",
            release: "1",
            date: "2020-12-01",
          },
          {
            id: 9,
            title: "Sensores do seu Satélite",
            desc: "Aprenda neste vídeo quais são os sensores do seu satélite, para que servem e o que os dados coletados indicam!",
            url: "https://www.youtube.com/embed/vWl8ppkPxoM",
            cover: "https://s2.glbimg.com/WWxe86E1yg9hQORPEQHcIPUDjKI=/512x320/smart/e.glbimg.com/og/ed/f/original/2014/12/17/281_corridaespacial_01.jpg",
            chip: "0",
            release: "0",
            date: "2021-02-01",
          },
          {
            id: 1,
            title: "Como ligar o seu CanSat",
            desc: "Aprenda a ligar o seu PION CanSat Educacional e acessar os dados dos sensores pelo celular via Wi-Fi em tempo real!",
            url: "https://www.youtube.com/embed/vWl8ppkPxoM",
            cover: "https://www.positivelyosceola.com/wp-content/uploads/2020/05/spacexmannedlaunch-948x640.jpg",
            chip: "1",
            release: "0",
            date: "2020-11-01",
          },
          {
            id: 2,
            title: "Como ligar o seu CubeSat",
            desc: "Aprenda a ligar o seu PION CubeSat Educacional e acessar os dados dos sensores pelo celular via Wi-Fi em tempo real!",
            url: "https://www.youtube.com/embed/vWl8ppkPxoM",
            cover: "https://static.independent.co.uk/s3fs-public/thumbnails/image/2019/07/19/10/elon-musk-spacex-starship-moon.jpg",
            chip: "2",
            release: "0",
            date: "2020-10-01",
          },
          {
            id: 3,
            title: "O que é um CanSat?",
            desc: "Descubra o que é um CanSat, o histórico e as missões já realizadas com esse satélite nesse rápido vídeo de introdução!",
            url: "https://www.youtube.com/embed/vWl8ppkPxoM",
            cover: "https://assets.entrepreneur.com/content/3x2/2000/1606422501-SpaceX.jpg",
            chip: "1",
            release: "0",
            date: "2020-05-01",
          },
                  {
            id: 4,
            title: "Desmontando o seu CanSat",
            desc: "Aprenda a desmontar corretamente o seu PION CanSat Educacional com o time de Engenheiros Aeroespaciais da PION!",
            url: "https://www.youtube.com/embed/vWl8ppkPxoM",
            cover: "https://cdn.arstechnica.net/wp-content/uploads/2021/01/50703878421_4c2b6e88c7_k-800x533.jpg",
            chip: "1",
            release: "0",
            date: "2019-12-01",
          },
                  {
            id: 5,
            title: "CubeSat - Introdução e Missões",
            desc: "Descubra o que é um CubeSat, o histórico e as missões já realizadas com esse satélite nesse rápido vídeo de introdução!",
            url: "https://www.youtube.com/embed/vWl8ppkPxoM",
            cover: "https://g.foolcdn.com/editorial/images/593749/spacex-falcon-9-rocket-launch-is-nasa.jpg",
            chip: "2",
            release: "0",
            date: "2020-02-01",
          },
                  {
            id: 6,
            title: "Introdução ao PION Classroom",
            desc: "A plataforma PION Classroom é a base para o direcionamento das atividades com os satélites do tipo CanSat e CubeSat!",
            url: "https://www.youtube.com/embed/vWl8ppkPxoM",
            cover: "https://i.ytimg.com/vi/qcISEOGjKpM/maxresdefault.jpg",
            chip: "0",
            release: "0",
            date: "2021-01-01",
          },
          {
            id: 7,
            title: "Exemplos de Missões Espaciais",
            desc: "Se junte ao time de Engenheiros Aeroespaciais da PION e veja exemplos de missões que podem ser realizadas com o seu satélite!",
            url: "https://www.youtube.com/embed/vWl8ppkPxoM",
            cover: "https://tecnoblog.net/meiobit/wp-content/uploads/2015/11/20151114foguete.jpg",
            chip: "4",
            release: "0",
            date: "2021-01-01",
          },
        ],
      }
    }
  }        
</script>

<style>
.v-list-item__subtitle {
  color:#000000;
  font-size: 12px;
}
.title-std {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}
.texto-normal {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: justify;
}
.texto-azul {
  color: #0D2556;
}
.texto-strong {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  color: #4A5057;

}
.texto-azul-strong {
  font-weight: bold;
  color: #0D2556;
}
.footer1 {
  margin-top: 0;
  text-align: center;
}
.footer2 {
  margin-top: 1vh;
  font-size: 12px;
  text-align: center;
  color: #50535A;
}
.profile-btn {
  font-family: 'Nunito';
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: normal;
}
</style>