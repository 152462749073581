<template>
  <v-container>
    <Dialog :dialog="alert" :message="alertText" @dismissed="alert = false"></Dialog>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" class="px-10 pt-5">
        <span class="title-std pb-2">Configurações do meu Satélite</span>
        <v-row>
          <v-col cols="7">
            <v-card
              class="rounded-lg pa-2"
              flat
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card-title justify="start"><span class="card-title">Meu Satélite</span></v-card-title>
                    <v-container fluid>
                      <v-row justify="space-around">
                        <v-col cols="4">
                          <template v-if="sat_type === 'CanSat'">
                            <v-img
                              max-height="28vh"
                              contain
                              src="@/assets/CanSat_1.svg"
                            >
                            </v-img>
                          </template>
                          <template v-else>
                            <v-img
                              max-height="28vh"
                              contain
                              src="@/assets/CubeSat_1.svg"
                            >
                            </v-img>
                          </template>
                        </v-col>
                        <v-col cols="8">
                          <v-row justify="start">
                            <span class="profile-name mt-3">PION {{sat_type}} Educacional</span>
                          </v-row>
                          <v-row justify="start" class="pt-6 pr-8">
                            <v-col cols="2" class="d-flex justify-start align-center">
                              <span class="profile-dis-field">Cor da Estrutura</span>
                            </v-col>
                            <v-col cols="10">
                              <v-text-field
                                label="Cor da Estrutura"
                                :value="color"
                                disabled
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="start" class="pr-8">
                            <v-col cols="2" class="d-flex justify-start align-center">
                              <span class="profile-dis-field">Série e Lote</span>
                            </v-col>
                            <v-col cols="5">
                              <v-text-field
                                label="Número de Série"
                                :value="serial"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col cols="5">
                              <v-text-field
                                label="Número do Lote"
                                :value="lote"
                                disabled
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="start" class="pr-8">
                            <v-col cols="2" class="d-flex justify-start align-center">
                              <span class="profile-dis-field">Versão e Fabricação</span>
                            </v-col>
                            <v-col cols="5">
                              <v-text-field
                                label="Versão do Satélite"
                                :value="version"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col cols="5">
                              <v-text-field
                                prepend-icon="mdi-calendar"
                                label="Data de Fabricação"
                                :value="manufacture"
                                disabled
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row justify="start" class="mt-3 profile-title">
                        <v-card-title justify="start"><span class="card-title">Garantia do Satélite</span></v-card-title>
                      </v-row>
                      <v-container fluid class="px-2">
                        <v-row justify="space-around">
                          <v-col cols="12" class="d-flex justify-start align-center">
                            <span class="texto-garantia">O seu kit <strong>PION Satélite Educacional</strong> é composto pelo <span class="font-italic">hardware</span>, componentes, estrutura e o acesso à plataforma exclusiva <strong>PION Classroom</strong> para até dez membros do seu projeto! Caso desejar, você poderá renovar o acesso a plataforma ou adicionar mais membros ao seu projeto!</span>
                          </v-col>
                        </v-row>
                        <v-row justify="center" class="pt-3">
                          <v-col cols="3" class="d-flex justify-start align-center">
                            <span class="profile-dis-field">Prazo de Garantia do Satélite</span>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              prepend-icon="mdi-calendar"
                              label="Garantia do Satélite"
                              value="30/09/2021"
                              disabled
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="3" class="d-flex justify-start align-center">
                            <span class="profile-dis-field">Vencimento do Acesso ao PION Clasroom</span>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              prepend-icon="mdi-calendar"
                              label="Vencimento da Subscrição"
                              value="31/12/2020"
                              disabled
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="space-around" class="pt-1 mb-n5">
                          <v-col cols="12" class="d-flex justify-center align-center">
                            <v-dialog
                              transition="dialog-top-transition"
                              max-width="600"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  dark
                                  rounded
                                  color="#0D2556"
                                  class="termos-btn"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon
                                    dark
                                    left
                                    small
                                  >
                                    mdi-rocket-launch
                                  </v-icon>
                                  Termos de Privacidade, Garantia e Condições 
                                </v-btn>
                              </template>
                              <template v-slot:default="dialog">
                                <v-card>
                                  <v-toolbar
                                    color="#0D2556"
                                    dark
                                  >PION Classroom | Termos de Privacidade, Garantia e Condições</v-toolbar>
                                  <v-card-text>
                                    <div class="texto-garantia pt-8">Acesse www.pionlabs.com.br para verificar os Termos de Privacidade, Garantia e Condições.</div>
                                  </v-card-text>
                                  <v-card-actions class="justify-end">
                                    <v-btn
                                      text
                                      @click="dialog.value = false"
                                    >Fechar</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </template>
                            </v-dialog>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-card
              class="rounded-lg pa-2"
              flat
            >
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card-title justify="start">
                      <span class="card-title">
                        Atualizar Firmware do meu Satélite
                        <v-tooltip bottom max-width="35vh" color="graypion">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="graypion"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span class="hint">Firmware é o programa instalado no seu satélite que faz a coleta e transmissão de dados, acionamento do Wi-Fi e que controla os sensores e luzes.</span>
                        </v-tooltip>
                      </span>
                    </v-card-title>
                    <v-container fluid class="pt-4 px-6">
                      <v-row justify="space-around">
                        <span class="firm-title"><strong>PION EduSat Firmware</strong> é o programa em C++ para o gerenciamento do seu satélite educacional desenvolvido pela PION.</span>
                      </v-row>
                      <v-row justify="space-around" class="pt-8">
                        <v-dialog
                          v-model="dialog1"
                          persistent
                          max-width="420px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <p class="firm-title"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span class="firm-link">Clique aqui</span> para aprender a atualizar o firmware do seu satélite. 
                            </p>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">Atualização de Firmware</span>
                            </v-card-title>
                            <v-subheader class="subheader">Itens necessários:</v-subheader>
                            <v-list dense class="px-4">
                              <v-list-item
                                v-for="(item, i) in items"
                                :key="i"
                              >
                                <v-list-item-icon>
                                  <v-icon v-text="item.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title v-text="item.text"></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>                            
                            <v-subheader class="pa-6 mb-3 mt-2 subheader">Com os itens indicados na lista acima, conecte o seu satélite ao computador via USB. Ligue o seu satélite e abra o programa da PION. Em seguida, selecione a porta correta de comunicação (exemplo: 'COM1'). Por fim, clique para executar.</v-subheader>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="gray"
                                text
                                @click="dialog1 = false"
                              >
                                Fechar
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-row>
                      <v-row justify="space-around" class="pt-4 pb-4">
                        <span class="firm-title">Download Disponível para Windows</span>
                      </v-row>
                      <v-row justify="space-around">

                        <v-dialog
                          v-model="dialog"
                          transition="dialog-top-transition"
                          max-width="320"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mt-4 py-3"
                              fab
                              dark
                              elevation="1"
                              color="#f2f2f2"
                              width="100"
                              height="100"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon dark color="graypion" size="60">
                                mdi-cloud-download-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:default="dialog">
                            <v-card>
                              <v-card-title class="profile-name">
                                <strong>PION EduSat Firmware</strong>
                              </v-card-title>
                              <v-card-text class="texto-garantia">Clique no botão abaixo para realizar o download do <strong>PION EduSat Firmware</strong>. APENAS para satélites da <strong>Versão 3:</strong></v-card-text>
                              <v-card-actions>
                              <v-btn
                                  rounde
                                  color="blue"
                                  light
                                  text
                                  href="https://github.com/pion-labs/pion-kits-firmware-uploader/releases/download/1.0.0/PIONFirmwareUploaderWindows.exe"
                                  target="_blank"
                                >
                                  DOWNLOAD
                                </v-btn>              
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="darken-1"
                                  text
                                  @click="dialog.value = false"
                                >
                                  Fechar
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </template>
                        </v-dialog>
                      </v-row>
                      <v-row class="d-flex justify-center text-center mt-4 pt-3">
                        <span class="firm-down">PION EduSat Firmware</span>
                      </v-row>
                      <v-row class=" d-flex justify-center text-center pt-2">
                        <v-col cols="5" class="d-flex align-center">
                          <v-row justify="end" class="firm-desc">Versão Atual: 1.0</v-row>
                        </v-col>
                        <v-col cols="1" justify="space-around" align-center><v-divider vertical></v-divider></v-col>
                        <v-col cols="5" class="d-flex align-center">
                          <v-row justify="start" class="firm-desc">Release: Julho de 2021</v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container fluid>
                      <span class="card-title">Dúvidas? Fale com a PION!</span>
                      <v-form
                        ref="messageBox"
                        class="pt-5"
                      >
                        <v-row justify="space-around">
                          <v-col cols="3" class="d-flex justify-start align-center">
                            <span class="profile-dis-field">Assunto</span>
                          </v-col>
                          <v-col cols="9">
                            <v-combobox
                              v-model="subject"
                              :items="questions"
                              required
                              :rules="[rules.required]"
                              label="Classifique o Assunto"
                              color="graypion"
                              dense
                            ></v-combobox>
                          </v-col>
                        </v-row>
                        <v-row justify="space-around">
                          <v-col cols="3" class="d-flex justify-start align-center">
                            <span class="profile-dis-field">Dúvida</span>
                          </v-col>
                          <v-col cols="9">
                            <v-textarea
                              v-model="message"
                              color="graypion"
                              required
                              :rules="[rules.required, rules.min]"
                              outlined
                              no-resize
                            >
                              <template v-slot:label>
                                <div>
                                  Fale um pouco mais sobre sua dúvida
                                </div>
                              </template>
                            </v-textarea>
                          </v-col>
                        </v-row>
                        <div class="text-center d-flex justify-center pb-1">
                          <v-btn
                            dark
                            outlined
                            rounded
                            color="#0D2556"
                            class="termos-btn"
                            @click="sendMessage"
                          >
                            Enviar Dúvida
                          </v-btn>
                        </div>
                      </v-form>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Dialog from '../components/Shared/Dialog.vue'

  export default {
    components: {
      Dialog
    },
    data () {
      return {
        dialog1: false,
        items: [
          { text: 'PION Satélite Educacional', icon: 'mdi-satellite-variant' },
          { text: 'Computador com acesso à Internet', icon: 'mdi-desktop-classic' },
          { text: 'Cabo USB', icon: 'mdi-cable-data' },
          { text: 'Última versão do PION EduSat Firmware', icon: 'mdi-application-cog' },
        ],
        videos: [
          { id: 1, url: "https://www.youtube.com/embed/vWl8ppkPxoM" },
        ],
        select: '',
        rules: {
          required: value => !!value || 'Obrigatório.',
          min: v => v.length >= 6 || 'Minimo 6 caracteres',
        },
        questions: [
          'Dúvidas Gerais',
          'Alteração do Perfil',
          'Problemas com o Satélite',
          'Outro Assunto',
        ],
        subject: "",
        message: "",
        alert: false,
        alertText: "",
      }
    },
    methods:{
      test (){
        console.log("Should be added later!")
        // this.$store.dispatch('signUp', {serial_used: "12344321"})
      }, 
      sendMessage(){
        if(this.$refs.messageBox.validate()){
          this.$store.dispatch('sendMessage',{subject:this.subject,message:this.message})
          this.alert = true
          this.alertText = "Mensagem enviada ao time da PION, aguarde a nossa resposta no seu e-mail cadastrado!"
        }
      }
    },
    computed:{
      sat(){
        return this.$store.getters.sat
      },
      sat_type(){
        if(this.$store.getters.sat)
          return this.$store.getters.sat.sat_type
        else
          return "CubeSat"
      },
      color(){
        if(this.$store.getters.sat)
          return this.$store.getters.sat.color
        else
          return "Cor"
      },
      serial(){
        if(this.$store.getters.sat)
          return this.$store.getters.sat.serial
        else
          return "000000"
      },
      lote(){
        if(this.$store.getters.sat)
          return this.$store.getters.sat.lote
        else
          return "0"
      },
      version(){
        if(this.$store.getters.sat)
          return this.$store.getters.sat.version
        else
          return "0"
      },
      manufacture(){
        if(this.$store.getters.sat)
          return this.$store.getters.sat.manufacture
        else
          return "20/12/2020"
      },
      warranty_exp(){
        if(this.$store.getters.sat)
          return this.$store.getters.sat.warranty_exp
        else
          return "20/12/2020"
      },
      classroom_exp(){
        if(this.$store.getters.sat)
          return this.$store.getters.sat.classroom_exp
        else
          return "20/12/2020"
      }
    },
    beforeMount: function() {
      this.$store.dispatch('loadSat')
    }

  }
</script>

<style scoped>
  .firm-title {
    font-family: 'Nunito';
    font-size: 15px;
    font-weight: 400;
    color: #50535A;
    text-align: center;
  }
  .subheader {
    font-family: 'Nunito';
    font-size: 12px;
    font-weight: 400;
    color: #50535A;
    text-align: left;
    padding-left: 6%;
  }
  .firm-down {
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 800;
    color: #0D2556;
    text-align: center;
  }
  .firm-link {
    font-family: 'Nunito';
    font-size: 15px;
    font-weight: 400;
    color: #0D2556;
  }
  .firm-desc {
    font-family: 'Nunito';
    font-size: 12px;
    font-weight: 400;
    color: #50535A;
    text-align: center;
  }  
  .profile-btn {
    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: normal;
  }
    .termos-btn {
    font-family: 'Nunito';
    font-size: 12px;
    font-weight: 500;
    letter-spacing: normal;
  }
  .alert-profile {
    font-family: 'Nunito';
    font-size: 13px;
    font-weight: 300;
  }
  .hint {
    font-family: 'Nunito';
    font-size: 12px;
  }
  .profile-dis-field {
    font-family: 'Nunito';
    font-size: 12px;
    font-weight: 400;
    color: #50535A;
  }
  .texto-garantia {
    font-family: 'Nunito';
    font-size: 15px;
    font-weight: 400;
    color: #50535A;
    text-align: justify;
  }
  .profile-title {
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 600;
    color: #50535A;
  }
  .profile-name {
    font-family: 'Nunito';
    font-size: 20px;
    font-weight: 700;
    color: #0D2556;
  }
  .profile-subname {
    font-family: 'Nunito';
    font-size: 15px;
    font-weight: 300;
    color: #50535A;
  }
  .profile-dados1 {
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 800;
    color: #50535A;
  }
  .profile-dados2 {
    font-family: 'Nunito';
    font-size: 12px;
    font-weight: 300;
    color: #50535A;
  }
  .card-title{
    font-family: 'Nunito';
    font-size: 18px;
    font-weight: 600;
    color: #50535A;
  }
  .title-std {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
  }
  .input-width{
    min-width: 170px;
  }
  .small-input-width{
    min-width: 90px;
  }
  .big-input-width{
    min-width: 200px;
  }
</style>