<template>
  <v-app id="inspire">

    <v-app-bar
      app
      color="white"
      flat
      height="150px"
    >
        <v-img
          src="@/assets/logo.svg"
          contain
          max-height="120px"
        ></v-img>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container>
        <v-row justify="center" align="center"> 

          <!-- <v-col
            cols="12"
            sm="2"
          >
            <v-sheet
              rounded="lg"
              min-height="268"
              class="grey lighten-3"
            >
            </v-sheet>
          </v-col> -->

          <v-col
            cols="12"
            sm="8"
          >
            <v-sheet
              min-height="50vh"
              rounded="lg"
              class="px-12 py-12"
            >
              <p class="title-std1 pb-2">Olimpíada Brasileira de Satélites MCTI</p>
              <p class="texto-normal1 pt-6">Onde está o meu kit PION Satélite Educacional?</p>
              <p class="texto-normal2 pt-6">Os classificados na <strong>Olimpíada Brasileira de Satélites MCTI</strong> para a <strong>Fase 2 com direito</strong> a receber um kit de satélite podem verificar onde estão seus PION CanSat Educacional ou <strong>PION CubeSat Educacional</strong> utilizando o <strong>e-mail do tutor/responsável pela equipe e o CEP</strong> informado à organização da OBSAT/MCTI. Caso você não encontre, por favor aguarde um dia ou informe pelo e-mail liftoff@pionlabs.com.br</p>
              <p class="texto-normal3 pt-6"><strong>Informar o E-mail do Tutor/Responsável:</strong>
                  <v-chip
                    x-small
                    class="ma-2"
                    color="blue"
                    text-color="white"
                  >
                    Atualizado em 09/08/2021
                  </v-chip>
              </p>

              <v-form
                ref="cep"
                v-model="cepForm"
                lazy-validation
              >
                <v-text-field
                  v-model="cep"
                  label="Insira o E-mail"
                  placeholder="E-mail"
                  :rules="[rules.required, rules.mincep]"
                  outlined
                  color="#0D2556"
                ></v-text-field>
                <div class="text-center d-flex justify-center mt-n4">
                  <v-btn
                    dark
                    color="#0D2556"
                    class="mt-4 mr-4"
                    :loading="loading"
                    @click="cepVerification()"
                  >
                    LOCALIZAR
                  </v-btn>
                </div>
                <v-dialog
                  v-model="dialog1"
                  width="500"
                >
                  <v-card>
                    <v-card-title><strong>Localize seu PION Satélite Educacional</strong></v-card-title>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Insira seu CEP (apenas números)"
                        single-line
                        hide-details
                        class="pr-8 pl-8 pb-4"
                      ></v-text-field>
                      <v-data-table
                        :headers="headers"
                        :items="rastreadores"
                        :search="search"
                        :items-per-page="5"
                        class="pl-8 pr-8 elevation-1"
                        :footer-props="{
                          'items-per-page-text':'CEPs por página',
                          'items-per-page-all-text':'Todos'
                        }"
                      >
                        <template v-slot:[`footer.page-text`]="items"> {{ items.pageStart }} a {{ items.pageStop }} de {{ items.itemsLength }} </template>
                      </v-data-table>
                  </v-card>
                </v-dialog>
              </v-form>
              <v-spacer></v-spacer>
              <div class="footer-reg justify-center pt-12">
                Feito com ♥ no Brasil
              </div>
            </v-sheet>
          </v-col>

          <!-- <v-col
            cols="12"
            sm="2"
          >
            <v-sheet
              rounded="lg"
              min-height="268"
              class="grey lighten-3"
            >
            </v-sheet>
          </v-col> -->
          
        </v-row>
        <Dialog :dialog="alert" :message="message" @dismissed="alert = false"></Dialog>
      </v-container>
    </v-main>

    <v-footer
      dark
      padless
    >
      <v-card
        class="flex"
        flat
        tile
      >
        <v-card-text class="white py-6 grey--text text-center">
          {{ new Date().getFullYear() }} — <strong>PION</strong>
        </v-card-text>
      </v-card>
    </v-footer>

  </v-app>
</template>

<script>
  import Dialog from '../components/Shared/Dialog.vue'

  export default {
    components: {
      Dialog,
    },
    data () {
        return {
          dialog1: false,
          rules: {
            required: value => !!value || 'Preencher o campo com o e-mail do tutor/responsável.',
            mincep: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || 'E-mail inválido.'
            }
          },
          alert: false,
          search: '',
          message: "",
          headers: [
            { text: 'CEP', value: 'cep' },
            { text: 'Rastreador dos Correios', value: 'rastreador' }
          ],
          icons: [
            'mdi-facebook',
            'mdi-twitter',
            'mdi-linkedin',
            'mdi-instagram',
          ],
        }
    },
    computed: {
      loading () {
        return this.$store.getters.loading
      },
      rastreadores(){
        return this.$store.getters.rastreadores
      }
    },
    beforeMount: function(){
      this.$store.dispatch('loadRastreadores')
    },
    methods: {
      async cepVerification(){
        if(this.$refs.cep.validate()){
          let cepExists = await this.$store.dispatch('checkcep',this.cep)
          if(cepExists)
            this.dialog1 = true
          else{
            this.alert = true
            this.message = "O e-mail informado não foi encontrado."
          }
        } else{
          this.alert = true
          this.message = "Não encontramos o e-mail informado. Por favor, insira o e-mail do tutor ou responsável pela equipe."
        }
      }
    }
  }
</script>

<style>
  .title-std1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    text-align: center;
    color: #0D2556;
  }
  .texto-normal1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    text-align: center;
  }
  .texto-normal2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    text-align: justify;
  }
  .texto-normal3 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    text-align: justify;
  }
  .footer-reg {
    font-size: 12px;
    text-align: center;
    color: #0D2556;
  }
</style>
