var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-10 pt-5",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-row',[_c('v-col',{staticClass:"justify-center",attrs:{"cols":"12","sm":"12"}},[_c('span',{staticClass:"title-std pb-2"},[_vm._v("Atividades")]),_c('v-card',{staticClass:"rounded-lg pa-3",attrs:{"flat":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-4",attrs:{"cols":"12"}},[_c('v-img',{attrs:{"src":require("@/assets/PION_Classroom_Gray.svg"),"max-height":"100px","contain":""}})],1)],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Procurar Atividade","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.activities,"search":_vm.search,"footer-props":{
                'items-per-page-text':'Atividades por página',
                'items-per-page-all-text':'Todas'
              }},scopedSlots:_vm._u([{key:"item.rating",fn:function(ref){
              var item = ref.item;
return [_c('v-rating',{attrs:{"length":"5","readonly":"true","small":"","dense":"","color":"graypion","background-color":"graypion"},model:{value:(item.rating),callback:function ($$v) {_vm.$set(item, "rating", $$v)},expression:"item.rating"}})]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [(item.status == '0')?_c('span',{staticClass:"status-0",on:{"click":function($event){return _vm.GoToActivity(item)}}},[_c('v-icon',{attrs:{"small":"","color":"pion"}},[_vm._v("mdi-arrow-right-drop-circle-outline")]),_vm._v(" Começar! ")],1):_vm._e(),(item.status == '1')?_c('span',{staticClass:"status-1",on:{"click":function($event){return _vm.GoToActivity(item)}}},[_c('v-icon',{attrs:{"small":"","color":"#F5B100"}},[_vm._v("mdi-history")]),_vm._v(" Continuar ")],1):_vm._e(),(item.status == '2')?_c('span',{staticClass:"status-2",on:{"click":function($event){return _vm.GoToActivity(item)}}},[_c('v-icon',{attrs:{"small":"","color":"#07BD3A"}},[_vm._v("mdi-check-circle-outline")]),_vm._v(" Concluída ")],1):_vm._e()]}},{key:"item.link",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.GoToActivity(item)}}},[_vm._v(" mdi-open-in-new ")])]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" a "+_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength)+" ")]}}],null,true)})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }