<template>
  <v-alert border="left" error dismissible @input="onClose" :value="true" color="warning">
    <span > {{message }} </span>
  </v-alert>
</template>

<script>
  export default {
    props: ['error'],
    methods: {
      onClose () {
        this.$emit('dismissed')
      }
    },
    computed: {
      message(){
        let text = this.error.message
        if(this.error.code == "auth/wrong-password"){
          text = "Senha não coincide com a cadastrada!"
        } else if(this.error.code == "auth/network-request-failed"){
          text = "Sem Conexão com a Internet"
        } else if(this.error.code == "auth/user-not-found"){
          text = "Usuário não encontrado"
        } else if(this.error.code == "auth/too-many-requests"){
          text = "Acesso temporariamente desabilitado para esse usuário devido a muitas falhas de login. Tente novamente mais tarde."
        }
        return text
      }
    }
  }
</script>