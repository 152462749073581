<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="6" class="px-10 pt-5">
        <v-row>
          <v-col cols="12" sm="12" class="justify-center">
            <span class="title-std pb-2">Introdução ao PION Classroom</span>
            <v-card
              class="rounded-lg pa-3"
              flat
            >
              <v-container fluid>
                <v-row class="px-4">
                  <p class="texto-title pt-4">Olá, seja bem vindo ao <span class="texto-azul-strong">PION Classroom!</span></p>
                  <p class="texto-normal2">Nesta plataforma, <span class="texto-azul-strong">em breve</span> você encontrará a base de informações para a melhor experiência com o seu <span class="texto-azul">PION Satélite Educacional.</span></p>
                  <p class="texto-normal2">Aguarde pela nossa <span class="texto-azul">Biblioteca</span>, que será constantemente atualizada com diversas vídeo-aulas, até uma base com diversas <span class="texto-azul">Atividades</span> para alunos, professores e entusiastas aprenderem mais sobre ciência, tecnologia, engenharia e matemática!<p>
                  <p class="texto-normal2">Vamos ao básico da nossa plataforma:</p>
                  <p class="texto-normal2">1) No momento, você <span class="texto-azul">já realizou</span> o seu cadastro para estar por dentro das novidades da plataforma PION Classroom.</p>
                  <p class="texto-normal2">2) Agora, você poderá acessar o <span class="texto-azul">Meu Satélite</span> no menu lateral para encontrar dados e informações sobre o seu satélite.</p>
                  <p class="texto-normal2">3) Não se esqueça de manter o seu <span class="texto-azul">Perfil</span> atualizado para receber as novidades!</p>
                  <p class="texto-normal2">4) Por fim, fique por dentro do conteúdo do seu <span class="texto-azul">PION Satélite Educacional</span> acessando o Wiki dos Satélite nos GitHub da PION!</p>
                  <v-timeline dense class="teste align-center">
                    <v-timeline-item
                      fill-dot
                      small
                      color="#8692AB"
                      icon-color="pion"
                      icon="mdi-check"
                    >
                      <span class="texto-normal">Conclusão do <span class="texto-azul">Primeiro Acesso</span> com o cadastro do seu satélite</span>
                    </v-timeline-item>
                    <v-timeline-item
                      fill-dot
                      small
                      color="#8692AB"
                      icon-color="pion"
                      icon="mdi-cog"
                    >
                    <span class="texto-normal">Acesse o <span class="texto-azul">Sat Wiki</span> para saber mais sobre o PION Satélite Educacional!</span>
                    </v-timeline-item>
                  </v-timeline>
                  <p class="texto-normal2 pt-5">Caso você tenha dúvidas, entre em contato com a <span class="texto-azul">PION!</span></p>
                </v-row>
              </v-container>
              <v-container fluid>

              </v-container>
              <div class="footer1 d-flex justify-center">
                <v-img
                  src="@/assets/logosimples.svg"
                  max-width="7vh"
                  contain
                ></v-img>
              </div>
              <div class="footer2 d-flex justify-center">
                  Feito com ♥ no Brasil
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="py-4 pt-5 px-10">
        <v-row>
          <v-col cols="12" sm="12" class="justify-center">
            <span class="title-std pb-2">PION Satélites Educacionais</span>
            <v-card
              class="rounded-lg pa-3"
              flat
              min-height="35vh"
            >
              <v-container fluid>
                <v-row class="pa-4 align-center mt-3 mb-3">
                  <v-img
                      src="https://firebasestorage.googleapis.com/v0/b/classroom-e67ad.appspot.com/o/Classroom%2Fpion-sats.png?alt=media&token=f7b331c4-0558-4711-91d6-e60dfc9a458f"
                      max-height="320"
										>
                  </v-img>
                  </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" class="justify-center">
            <span class="title-std pb-2">Últimas Informações</span>
            <v-card
              class="rounded-lg pa-3"
              flat
            >
              <v-container fluid>
                <v-row class="px-2">
                  <v-list three-line>
                    <template v-for="(item, index) in items">                      
                      <v-divider
                        v-if="item.divider"
                        :key="index"
                        :inset="item.inset"
                      ></v-divider>
                      <v-list-item
                        v-else
                        :key="item.title"
                      >
                        <v-list-item-avatar>
                          <v-img :src="item.avatar"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="teste2" v-html="item.title"></v-list-item-title>
                          <v-list-item-subtitle class="text--pion" v-html="item.subtitle"></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-row>
              </v-container>
              <v-container fluid class="justify-center">
                <v-row
                  justify="space-around"
                  class="mb-1"
                >
                  <v-btn
                    icon
                    color="graypion"
                    href="https://twitter.com/PIONspace"
                    target="blank"
                  >
                    <v-icon medium>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      items: [
        {
          avatar: 'https://i.imgur.com/cOr2pi3.jpg',
          title: 'Suporte e Garantia',
          subtitle: '23/07/2021 - Você sabe o que são CanSats e CubeSats? Em breve, a nossa biblioteca de atividades e aulas estará disponível para acesso!',
        },
        { divider: true, inset: true },
        {
          avatar: 'https://i.imgur.com/cOr2pi3.jpg',
          title: 'CanSats e CubeSats?!',
          subtitle: '02/04/2021 - Você possui alguma dúvida ou está com alum problema no seu satélite? Acesse o nosso canal de ajuda no menu superior da nossa plataforma.',
        },
        { divider: true, inset: true },
        {
          avatar: 'https://i.imgur.com/cOr2pi3.jpg',
          title: 'Contagem regressiva... Lançamento!',
          subtitle: '01/01/2021 - A versão beta da plataforma PION Classroom está online para nossos futuros engenheiros espaciais terem a melhor experiência com os satélites da PION!</span>',
        },
      ],
    }),
  }
</script>

<style>
.teste2 {
  color: #4A5057;
  font-family: Nunito;
  font-weight: 600;
  font-size: 20px;
}
.v-list-item__subtitle {
  color:#000000;
  font-size: 12px;
}
.title-std {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}
.texto-normal {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: justify;
}
.texto-normal2 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: justify;
}
.texto-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: justify;
}
.texto-azul {
  color: #0D2556;
}
.texto-strong {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  color: #4A5057;

}
.texto-azul-strong {
  font-weight: bold;
  color: #0D2556;
}
.footer1 {
  margin-top: 0;
  text-align: center;
}
.footer2 {
  margin-top: 1vh;
  font-size: 12px;
  text-align: center;
  color: #50535A;
}
</style>