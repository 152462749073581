<template>
  <Navigation>
    <transition name="slide-x-transition" mode="out-in">
      <router-view :key="$router.currentRoute.path" ></router-view>
    </transition>
  </Navigation>
</template>

<script>
import Navigation from '@/components/Navigation'
export default {
  name: 'App',
  components: {
    Navigation,
  },
}
</script>

<style>
</style>