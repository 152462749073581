<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" class="px-10 pt-5">
        <v-row>
          <v-col cols="3"></v-col>
          <v-col cols="6">
            <v-card
              class="rounded-lg pa-2"
              flat
            >
              <v-container fluid>
                <v-img
                  src="@/assets/logo.svg"
                  max-height="160px"
                  contain
                ></v-img>
                <v-card-title>Sobre a PION</v-card-title>
                <v-card-text>
                  <span>A <strong>PION</strong> foi fundada por engenheiros aeroespaciais brasileiros em 2019 com o foco na democratização ao acesso ao Espaço. O nosso propósito é tornar simples e acessíveis as tecnologias espaciais para empresas, instituições e a socidade. Somos uma startup desenvolvendo <strong>satélites e foguetes!</strong></span>
                </v-card-text>
                <v-container fluid>
                  <v-row class="pa-4 align-center">
                    <iframe width="800" height="320" src="https://www.youtube.com/embed/vWl8ppkPxoM?rel=0&amp;controls=0" frameborder="0" controls="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </v-row>
                </v-container>
                <v-card-text>
                  <span>Nosso time de engenheiros é movido a café e "propeletente de foguete" para desenvolver ferramentas e experiências incríveis para os estudantes brasileiros! A <strong>PION Labs Engenharia Ltda.</strong> agradece o seu suporte na utilização do <strong>PION Classroom!</strong> Ad astra!</span>
                </v-card-text>
              </v-container>
              <div class="d-flex justify-center footer-about pb-3">
                Feito com ♥ no Brasil
              </div>
            </v-card>
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        items: [
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          },
        ],
      }
    },
  }
</script>

<style>
  .footer-about {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: #0D2556;
  }
</style>