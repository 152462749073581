<template>
  <div v-if="!isMobile()">
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col sm="9" class="d-none d-sm-flex">
            <div v-if="error" style="position: absolute;z-index:1;left: 30%;" class="mt-5">
              <app-alert @dismissed="onDismissed" :error="error" ></app-alert>
            </div>
            <v-img
              src="@/assets/background.jpg"
              style="object-fit: cover;"
              height="100vh"
            ></v-img>
          
          </v-col>
          <v-col sm="3">
            <v-row justify="center">
              <v-col cols="12" class="logo-margin">
                <v-img
                  src="@/assets/logo.svg"
                  max-height="125px"
                  contain
                ></v-img>
              </v-col>
              <div class="text-center d-flex justify-center mt-8">
                Acesse a plataforma PION Classroom:
              </div>
                <v-col cols="10">
                  <v-form
                    ref="login"
                    v-model="valid"
                    lazy-validation
                  >
                    <v-text-field
                      v-model="email"
                      :rules="[rules.required, rules.email]"
                      label="E-mail"
                      required
                      outlined
                      placeholder="E-mail"
                      color="#0D2556"
                    ></v-text-field>
                    <v-text-field
                      v-model="password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Senha"
                      counter
                      required
                      outlined
                      placeholder="Senha"
                      color="#0D2556"
                      @click:append="show1 = !show1"
                    ></v-text-field>
                    <div class="text-center d-flex justify-center mt-n4">
                      <v-btn
                      dark
                      color="#0D2556"
                      class="mr-4"
                      @click="login"
                    >
                      Entrar
                    </v-btn>
                    </div>
                  </v-form>
                  <div class="text-center d-flex justify-center font-weight-thin mt-4">
                    Esqueceu a sua senha?
                    <v-dialog
                      v-model="dialog1"
                      persistent
                      max-width="600px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <p class="pl-1 font-weight-thin darkblue--text"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Clique aqui.
                        </p>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Recuperação de Senha</span>
                        </v-card-title>
                        <v-card-text>
                          <div class="mt-4">
                            Você não lembra da sua senha? Tudo bem, até os melhores engenheiros espaciais também esquecem.
                            Para recuperá-la será bem simples, apenas preencha o campo com seu e-mail e aguarde a sua nova senha!
                          </div>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  label="E-mail"
                                  :rules="[rules.required, rules.email]"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="gray"
                            text
                            @click="dialog1 = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            dark
                            color="#0D2556"
                            @click="dialog1 = false"
                          >
                            Enviar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                  <div class="text-center d-flex justify-center mt-8 mb-3">
                    Primeiro acesso ao PION Classroom:
                  </div>
                    <v-form
                      ref="serial"
                      v-model="serialForm"
                      lazy-validation
                    >
                      <v-text-field
                        v-model="serial"
                        label="Número de Série"
                        placeholder="Número de Série"
                        :rules="[rules.required, rules.minSerial]"
                        outlined
                        color="#0D2556"
                      ></v-text-field>
                        <div class="text-center d-flex justify-center mt-n4">
                          <v-btn
                            dark
                            color="#0D2556"
                            class="mt-4 mr-4"
                            :loading="loading"
                            @click="serialVerification()"
                          >
                            Cadastrar
                          </v-btn>
                        </div>
                    <v-dialog
                      v-model="cadastro"
                      persistent
                      max-width="1100px"
                      overlay-color="lightpion"
                      overlay-opacity="1"
                    >     
                      <v-card v-if="!$vuetify.breakpoint.mobile" flat>
                        <v-row no-gutters>
                          <v-col cols="4" class="bottom-gradient d-flex align-content-center flex-wrap">
                            <v-container fluid>
                              <v-img
                                src="@/assets/PION_Classroom.svg"
                                max-height="100px"
                                contain
                              ></v-img>
                                <p class="signup-head mt-10 mx-4">Vamos criar a sua conta!</p>
                                <p class="signup-text mt-5 mx-4 justfify">O cadastro para acessar a nossa plataforma não vai levar mais do que três minutos!</p>
                              <div class="footer1 mt-10 d-flex justify-center">
                                <v-img
                                  src="@/assets/LogoSimplesFFF.svg"
                                  max-width="5vh"
                                  contain
                                ></v-img>
                              </div>
                              <div class="footer-reg justify-center">
                                Feito com ♥ no Brasil
                              </div>
                            </v-container>
                          </v-col>
                            <v-col cols="8" class="pa-4">
                            <v-stepper v-model="e1" class="elevation-0">
                              <v-stepper-header class="elevation-0">
                                <v-stepper-step
                                  :complete="e1 > 1"
                                  step="1"
                                  color="#0D2556"
                                >
                                  <div class="step-text">Criação do Perfil</div>
                                </v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step
                                  :complete="e1 > 2"
                                  step="2"
                                  color="#0D2556"
                                >
                                  <div class="step-text">Informações Adicionais</div>
                                </v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step step="3" color="#0D2556">
                                  <div class="step-text">Dados da Conta</div>
                                </v-stepper-step>
                              </v-stepper-header>
                              <v-stepper-items>
                                <v-stepper-content step="1">
                                  <v-card
                                    class="mb-0 elevation-0"
                                    height="450px"
                                  >
                                    <v-card-title class="form-reg-title mb-0">Criação do Perfil</v-card-title>
                                    <v-card-text>
                                      <v-form
                                        ref="firstStep"
                                        v-model="step1"
                                        lazy-validation
                                      >
                                        <v-text-field
                                          v-model="name"
                                          label="Nome Completo"
                                          required
                                          :rules="[rules.required]"
                                          color="#0D2556"
                                          class="mx-12">
                                        ></v-text-field>
                                        <v-text-field
                                          v-model="address"
                                          label="Endereço"
                                          required
                                          :rules="[rules.required]"
                                          color="#0D2556"
                                          class="mx-12">
                                        ></v-text-field>
                                        <v-row>
                                          <v-col
                                            cols="6"
                                          >
                                            <v-text-field
                                              v-model="city"
                                              label="Cidade"
                                              required
                                              :rules="[rules.required]"
                                              color="#0D2556"
                                              class="ml-12">
                                            ></v-text-field>
                                          </v-col>
                                          <v-col
                                            cols="3"
                                          >
                                            <v-select
                                              v-model="uf"
                                              :items="ufs"
                                              label="Estado"
                                              required
                                              :rules="[rules.required]"
                                              class="mx-0">
                                            ></v-select>
                                          </v-col>
                                          <v-col
                                            cols="3"
                                          >
                                            <v-text-field
                                              v-model="cep"
                                              label="CEP"
                                              required
                                              :rules="[rules.required]"
                                              color="#0D2556"
                                              class="mr-12"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-text-field
                                          v-model="celular"
                                          label="Celular"
                                          required
                                          :rules="[rules.required]"
                                          color="#0D2556"
                                          class="mx-12"
                                        ></v-text-field>
                                        <v-row>
                                          <v-col cols="6">
                                            <v-dialog
                                              ref="dateMenu"
                                              v-model="dateMenu"
                                              :return-value.sync="dob"
                                              width="290px"
                                            >
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                  class="ml-12"
                                                  v-model="dob"
                                                  label="Data de Nascimento"
                                                  append-icon="mdi-calendar"
                                                  readonly
                                                  :rules="[rules.required]"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                ></v-text-field>
                                              </template>
                                              <v-date-picker
                                                ref="picker"
                                                v-model="dob"
                                                :max="new Date().toISOString().substr(0, 10)"
                                                min="1950-01-01"
                                                locale="pt-br"
                                                @change="save"
                                              ></v-date-picker>
                                            </v-dialog>
                                          </v-col>
                                          <v-col cols="6">
                                          <v-autocomplete
                                            v-model="genero"
                                            :items="generos"
                                            label="Gênero"
                                            required
                                            :rules="[rules.required]"
                                            class="mr-12"
                                            no-data-text="Opção não encontrada"
                                          ></v-autocomplete>
                                          </v-col>
                                        </v-row>
                                      </v-form>
                                    </v-card-text>
                                  </v-card>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="cadastro = false">
                                      Cancelar
                                    </v-btn>
                                    <v-btn
                                      color="lightpion"
                                      dark
                                      @click="step1Validation()"
                                    >
                                      Continuar
                                    </v-btn>
                                  </v-card-actions>
                                </v-stepper-content>
                                <v-stepper-content step="2">
                                  <v-card
                                    class="mb-0 elevation-0"
                                    height="450px"
                                  >
                                    <v-card-title class="form-reg-title mb-0">Informações Adicionais</v-card-title>
                                    <v-card-text>
                                      <v-form
                                        ref="secondStep"
                                        v-model="step2"
                                        lazy-validation
                                      >
                                        <v-select
                                          v-model="funcao"
                                          :items="funcoes"
                                          label="Qual é o seu cargo ou função?"
                                          required
                                          :rules=[rules.required]
                                          class="mx-12"
                                        ></v-select>
                                        <v-text-field
                                          v-model="institution"
                                          label="Nome da sua Escola, Universidade, Instituição ou Empresa"
                                          required
                                          :rules=[rules.required]
                                          color="#0D2556"
                                          class="mx-12">
                                        </v-text-field>
                                        <v-row>
                                          <v-col
                                            cols="9"
                                          >
                                            <v-text-field
                                              v-model="institution_city"
                                              label="Cidade"
                                              required
                                              :rules=[rules.required]
                                              color="#0D2556"
                                              class="ml-12">
                                            </v-text-field>
                                          </v-col>
                                          <v-col
                                            cols="2"
                                          >
                                            <v-select
                                              v-model="institution_uf"
                                              :items="ufs"
                                              label="Estado"
                                              required
                                              :rules=[rules.required]
                                              class="mx-0"
                                            ></v-select>
                                          </v-col>
                                        </v-row>
                                        <v-radio-group 
                                          v-model="radios" 
                                          class="mx-12"
                                          required
                                          :rules=[rules.required]
                                        >
                                          <template v-slot:label>
                                            <div>Você é participante da <strong>Olimpíada Brasileira de Satélites do MCTI?</strong></div>
                                          </template>
                                          <v-radio value="Sim">
                                            <template v-slot:label>
                                              <div>Sim, sou participante da <strong class="pion--text">OBSAT</strong></div>
                                            </template>
                                          </v-radio>
                                          <v-radio value="Não">
                                            <template v-slot:label>
                                              <div>Não, eu <strong class="graypion--text">não</strong> sou participante do evento</div>
                                            </template>
                                          </v-radio>
                                        </v-radio-group>
                                      </v-form>
                                    </v-card-text>
                                  </v-card>                              
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="e1 = 1">
                                      Voltar
                                    </v-btn>
                                    <v-btn
                                      color="lightpion"
                                      dark
                                      @click="step2Validation()"
                                    >
                                      Continuar
                                    </v-btn>
                                  </v-card-actions>
                                </v-stepper-content>
                                <v-stepper-content step="3">
                                  <v-card
                                    class="mb-0 elevation-0"
                                    height="450px"
                                  >
                                  <v-card-title class="form-reg-title mb-0">Dados da Conta</v-card-title>
                                    <v-card-text>
                                      <v-form
                                        ref="thirdStep"
                                        v-model="step3"
                                        lazy-validation
                                      >
                                        <v-text-field
                                          label="Número de Série do PION Satélite Educacional"
                                          :value=serial
                                          class="mx-12"
                                          disabled
                                        ></v-text-field>
                                        <v-text-field
                                          v-model="email"
                                          :rules="[rules.required, rules.email]"
                                          label="E-mail"
                                          required
                                          placeholder="E-mail"
                                          color="#0D2556"
                                          class="mx-12"
                                        ></v-text-field>
                                        <v-text-field
                                          v-model="password"
                                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                          :rules="[rules.required, rules.min]"
                                          :type="show1 ? 'text' : 'password'"
                                          name="input-10-1"
                                          label="Senha"
                                          counter
                                          required
                                          placeholder="Senha"
                                          color="#0D2556"
                                          class="mx-12"
                                          @click:append="show1 = !show1"
                                        ></v-text-field>
                                        <v-text-field
                                          v-model="password2"
                                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                          :rules="[rules.required, rules.min]"
                                          :type="show1 ? 'text' : 'password'"
                                          name="input-10-1"
                                          label="Repita a Senha"
                                          counter
                                          required
                                          placeholder="Repita a Senha"
                                          color="#0D2556"
                                          class="mx-12"
                                          @click:append="show1 = !show1"
                                        ></v-text-field>
                                        <v-checkbox
                                          v-model="checkbox"
                                          :rules="[v => !!v || 'Você precisa aceitar para continuar!']"
                                          label="Você aceita os Termos de Uso, Privacidade e Garantia?"
                                          required
                                          class="mx-12"
                                        ></v-checkbox>
                                      </v-form>
                                    </v-card-text>
                                  </v-card>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="e1 = 2">
                                      Voltar
                                    </v-btn>
                                    <v-btn
                                      color="pion"
                                      dark
                                      @click="step3Validation()"
                                    >
                                      Cadastrar
                                      <v-icon
                                        right
                                        dark
                                      >
                                        mdi-arrow-right-bold-circle
                                      </v-icon>
                                    </v-btn>
                                  </v-card-actions>
                                </v-stepper-content>
                              </v-stepper-items>
                            </v-stepper>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-card v-else flat>
                        <v-container fluid>
                          <v-img
                            src="@/assets/PION_Classroom_Gray.svg"
                            max-height="100px"
                            contain
                          ></v-img>
                            <div class="text-center">
                              <v-icon size="80" class="mt-8">
                                mdi-cellphone-erase
                              </v-icon>
                              <p class="mt-8 mx-4">Por enquanto o sistema não está otimizado para uso em celular</p>
                              <p >Entre novamente pelo seu computador!</p>
                                
                                <v-btn 
                                  color="lightpion"
                                  dark 
                                  @click="cadastro = false"
                                >
                                  Cancelar
                                </v-btn>
                            </div>
                        </v-container>
                      </v-card>
                    </v-dialog>
                    </v-form>
                  <div class="mt-2 text-center d-flex justify-center font-weight-thin">
                    O que é
                    <v-dialog
                      v-model="dialog2"
                      persistent
                      max-width="600px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <p class="pl-1 font-weight-thin darkblue--text"
                          v-bind="attrs"
                          v-on="on"
                        >
                          número de série?
                        </p>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Número de Série</span>
                        </v-card-title>
                        <v-card-text>
                          <div class="mt-4">
                            O número de série é único para cada satélite educacional da <strong>PION</strong>. O número foi enviado junto ao documento de garantia.
                            Entretanto, caso você não possua mais esse documento, existe ainda outra forma de verificar o número de série do seu satélite:
                          </div>
                        </v-card-text>
                        <v-row no-gutters>
                          <v-col sm="6">
                            <v-timeline
                              align-center
                              dense
                              class="timeline"
                            >
                              <v-timeline-item small color="#50535a">Ligue o seu <strong>PION CanSat ou CubeSat</strong></v-timeline-item>
                              <v-timeline-item small color="#50535a">Confirme acionamento do satélite</v-timeline-item>
                              <v-timeline-item small color="#50535a">Acione o Wi-Fi do seu celular</v-timeline-item>
                              <v-timeline-item small color="#50535a">Procure as redes próximas</v-timeline-item>
                              <v-timeline-item small color="#50535a">Encontre a rede do seu satélite</v-timeline-item>
                              <v-timeline-item small color="#50535a">O <strong>número de série</strong> é parte do nome da rede do seu CanSat ou CubeSat</v-timeline-item>
                              <v-timeline-item small color="#50535a">No exemplo, o número de série é <strong>123456</strong></v-timeline-item>
                            </v-timeline>
                          </v-col>
                          <v-col sm="6">
                            <v-img
                              src="@/assets/mobile.svg"
                              max-height="400px"
                              contain
                            ></v-img>
                          </v-col>
                        </v-row>
                        <v-card-actions class="mt-2">
                          <v-spacer></v-spacer>
                          <v-btn
                            dark
                            color="#0D2556"
                            @click="dialog2 = false"
                          >
                            Fechar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="footer d-flex justify-center font-weight-thin">
                    © 2021 PION. Todos os Direitos Reservados.
                  </div>
                </v-col>
            </v-row>
          </v-col>
        </v-row>
        <Dialog :dialog="alert" :message="message" @dismissed="alert = false"></Dialog>
      </v-container>
  </div>
  <div v-else>
    <v-row justify="center">
      <v-col cols="12" class="logo-margin">
        <v-img
          src="@/assets/logo.svg"
          max-height="125px"
          contain
        ></v-img>
        <div class="text-center d-flex justify-center mt-12 mx-6">
          A plataforma PION Classroom foi feita para acesso por meio de desktops e laptops.<br><br><br>Obrigado :)
        </div>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import Dialog from '../components/Shared/Dialog.vue'

  export default {
    components: {
      Dialog
    },
    data () {
      return {
        valid:"",
        dateMenu: "",
        serialForm: false,
        step1: null,
        step2: null,
        step3: null,
        overlay: false,
        name:"",
        city:"",
        address:"",
        cep:"",
        radios: "",
        celular:"",
        dob:"",
        institution:"",
        institution_city:"",
        institution_uf:"",
        dialog1: false,
        cadastro: false,
        dialog2: false,
        show1: false,
        password: '',
        password2: "",
        checkbox: null,
        email: '',
        serial:'',
        e1: 1,
        uf: null,
        ufs: [
          'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO',
        ],
        funcao: null,
        funcoes: [
          'Estudante','Professor', 'Pesquisador', 'Entusiasta', 'Outro',
        ],
        genero: null,
        generos: [
          'Feminino', 'Masculino', 'Outro', 'Prefiro não dizer',
        ],
        rules: {
          required: value => !!value || 'Preencher campo.',
          min: v => v.length >= 6 || 'Verificar senha.',
          minSerial: v => {
            const pattern = /^[0-9]{6,}$/ 
            return pattern.test(v) || 'Verificar Número Serial.'
          },
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'E-mail inválido.'
          },
        },        
        alert: false,
        message: "",
      }
    },
    computed: {
      user(){
        return this.$store.getters.user
      },
      error () {
        return this.$store.getters.error
      },
      loading () {
        return this.$store.getters.loading
      },
    },
    watch: {
      user(value){
        if (value!==null&&value!==undefined){
          this.$router.push('/webapp/home')
        } 
      },
      dateMenu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },
    methods: {
      isMobile() {
          if( screen.width <= 760 ) {
              return true;
          }
          else {
              return false;
          }
      },
      login(){
        if(this.$refs.login.validate()){
          let payload = {
            email: this.email,
            password: this.password,
          }
          this.$store.dispatch('signIn', payload)
        }
      },
      async serialVerification(){
        if(this.$refs.serial.validate()){
          let serialExists = await this.$store.dispatch('checkSerial',this.serial)
          if(serialExists)
            this.cadastro = true;
          else{
            this.alert = true
            this.message = "Esse serial ainda não está cadastrado no nosso banco de dados, verifique se o número existe e é válido!"
          }
        } else{
          this.alert = true
          this.message = "Verifique se o seu número serial está correto"
        }
      },
      step1Validation(){
        if(this.$refs.firstStep.validate()){
          this.e1 = 2
        }
      },
      step2Validation(){
        if(this.$refs.secondStep.validate()){
          this.e1 = 3
        }
      },
      step3Validation(){
        if(this.$refs.thirdStep.validate()){
          let payload = {
            email: this.email,
            password: this.password,
            name: this.name,
            address: this.address,
            city: this.city,
            uf: this.uf,
            cep: this.cep,
            celular: this.celular,
            dob: this.dob,
            genero: this.genero,
            funcao: this.funcao,
            institution: this.institution,
            institution_city: this.institution_city,
            institution_uf: this.institution_uf,
            obsat: this.radios,
            termos: this.checkbox,
            serial_used: this.serial
          }
          this.$store.dispatch('signUp', payload)
          this.cadastro = false
        }
      },
      save (dob) {
        this.$refs.dateMenu.save(dob)
      },
      onDismissed () {
        this.$store.dispatch('clearError')
      },
    }
  }
</script>

<style>
.form-reg-title {
  font-size: 20px;
  text-align: center;
  font-family: 'Nunito';
  font-weight: 800;
  letter-spacing: 0;
  color: #27a9e1;
}
.step-text {
  font-size: 15px;
  text-align: center;
  font-family: 'Nunito';
  font-weight: 600;
  letter-spacing: 0;
  color: #50535A;
}
.signup-head {
  font-size: 20px;
  text-align: center;
  font-family: 'Nunito';
  font-weight: 600;
  letter-spacing: 0;
  color: #ffffff;
}
.signup-text {
  font-size: 14px;
  text-align: center;
  font-family: 'Nunito';
  font-weight: 200;
  letter-spacing: 0;
  color: #ffffff;
}
.logo-margin {
  margin-top: 12vh;
}
.v-application {
  font-family: 'Nunito' !important;
}
.footer {
  margin-top: 8vh;
  font-size: 13px;
  text-align: center;
}
.timeline {
  font-size: 13px;
  color: #50535a;
}
.bottom-gradient {
  background-image: linear-gradient(#0D2556, #2f4a81);
}
.footer1 {
  margin-top: 3vh;
  font-size: 13px;
  text-align: center;
}
.footer-reg {
  margin-top: 1vh;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
}
</style>
