<template>
  <v-dialog
    v-model="model"
    max-width="400"
  >
    <v-card>
      <v-card-title class="headline">
        Atenção!
      </v-card-title>

      <v-card-text>
        {{message}}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="lightpion"
          text
          @click="onClose"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialog','message'],
  computed:{
    model: {
      get(){
        return this.dialog
      },
      set(){
        return this.dialog
      }
    }
  },
  methods: {
    onClose () {
      this.model = false
      this.$emit('dismissed')
    }
  },
}
</script>

<style>

</style>